.input-shorttext {
  color: var(--black);
  background-color: var(--white);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  font-size: 14px;
  margin: 12px;
  padding: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: 0;
  height: 40px;
  width: calc(100% - 24px);
  flex: 1;
  font-weight: 400;
}

.noMargin {
  margin: 12px 0px !important;
}

.rebo {
  font-weight: 600;
  font-size: 17px;
  padding-left: 12px;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

.react-datepicker-wrapper {
  width: 100%;
}

.closeIcon {
  display: flex;
  align-self: flex-end;
}

.dropdownPrp div:hover {
  background-color: #d8f0f2;

  /* Change this to your desired highlight color */
  color: var(--primary);
  /* Change this to your desired highlight color */
  cursor: pointer;
}

.buttonPrep {
  margin: 6px;
  padding: 6px 6px;
  outline: 0;
  border: 0;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  min-width: 100px;
  font-family: "Poppins";
}

.css-coz5r0-MuiButtonBase-root-MuiIconButton-root {
  z-index: 0 !important;
}

.emName {
  margin-top: 120px;
  color: var(--primary);
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
}

.react-datepicker-wrapper {
  width: 100%;
  display: inline-block;
  padding: 0;
  border: 0;
}

.avatarPro {
  position: absolute;
  top: 40px;
  left: 5px;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.05);
  contain: strict;
}

.input-shorttexts {
  color: var(--black);
  background-color: var(--white);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  font-size: 12px;
  padding: 5px;
  text-decoration: dotted;
  border: 1px solid #ccc;
  box-sizing: border-box;
  outline: 0;
  flex: 1 1;
  font-weight: 400;
}

.input-shorttext::-webkit-input-placeholder {
  text-transform: initial;
}

.input-shorttext:-moz-placeholder {
  text-transform: initial;
}

.input-shorttext::-moz-placeholder {
  text-transform: initial;
}

.input-shorttext:-ms-input-placeholder {
  text-transform: initial;
}

.popsC {
  padding: 0 6px !important;
  justify-content: start;
}

.chrome-picker {
  margin: 0px 0px 0px 12px !important;
  background: rgb(255, 255, 255);
  border-radius: 4px !important;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 2px, rgb(0 0 0 / 30%) 0px 4px 8px;
  box-sizing: initial !important;
  font-family: inherit !important;
  width: calc(100% - 24px) !important;
}

#input-time {
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: 0;
  height: 40px;
  padding-left: 12px;
  flex: 1 1;
  font-weight: 400;
  background: rgb(255, 255, 255);
}

#input-shorttext {
  position: relative;
  color: var(--black);
  background-color: var(--white);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  font-size: 14px;
  margin: 12px;
  padding: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: 0;
  height: 40px;
  width: calc(100% - 24px);
  flex: 1;
  font-weight: 400;
}

.input-shorttextAtt {
  position: relative;
  color: var(--black);
  background-color: var(--white);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  font-size: 14px;
  margin: 12px;
  padding: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: 0;
  height: 40px;
  min-width: 100px;
  width: calc(100% - 24px);
  flex: 1;
  font-weight: 400;
}

.formik-s-btn {
  margin: 12px;
  height: 65px;
  width: 85px;
  align-items: center;
  height: 34px;

  border: 1.5px solid #1296b0 !important;
  border-radius: 3px;
}

.input-time {
  color: var(--black);
  background-color: var(--white);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  margin: 12px;
  padding: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: 0;
  height: 40px;
  width: calc(100% - 24px);
  flex: 1;
}

.input-shorttext:hover {
  border: 1px solid var(--primary-hover);
}

.input-shorttext:focus {
  border: 1px solid var(--primary);
}

.input-longtext {
  color: var(--black);
  background-color: var(--white);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  margin: 12px;
  padding: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: 0;
  height: 40px;
  min-height: 40px;
  max-height: 300px;
  width: calc(100% - 24px);
  font-family: "Poppins";
  font-size: 14px;
}

.rdw-editor-main {
  background-color: var(--white);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  margin: 12px;
  padding: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: 0;
  width: calc(100% - 24px);
}

.rdw-editor-main:hover {
  border: 1px solid var(--primary-hover);
}

.rdw-editor-toolbar {
  width: calc(100% - 24px);
  margin: 12px;
  padding: 12px;
}

.input-longtext:hover {
  border: 1px solid var(--primary-hover);
}

.input-longtext:focus {
  outline: none;
}

.ql-snow .ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 0px #5bc4bf;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
  left: -1px;
  top: 37px;
}

.label-custom {
  font-weight: 400;
  font-size: 15px;
  padding: 10px 13px;
  color: #555555;
  display: block;
  font-family: "Poppins";
}

.formCust {
  overflow: auto;
  height: calc(100% - 76px) !important;
  padding-left: 12px;
  padding-right: 12px;
}

.formComp {
  height: calc(100% - 120px);
  padding-left: 12px;
  padding-right: 12px;
}

.label-tax {
  font-weight: 400;
  font-size: 13px;
  padding: 4px 3px;
  color: #555555;
  display: block;
  font-family: "Poppins";
}

.repeatedTotla {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createFootCust {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  background-color: #f8f8f8f3;
  position: sticky;
  bottom: 0px;
  width: 100%;
  z-index: 1;
  height: 65px;
  align-items: center;
}

.createFootSep {
  display: flex;
  justify-content: flex-end;
  background-color: var(--form-background);
  position: sticky;
  bottom: 0px;
  width: 100%;
  height: 65px;
  align-items: center;
}

.bor {
  border: 1px solid var(--primary) !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerRadio {
  width: 10.62px;
  height: 10.62px;
  color: #1296b0;
  background: #1296b0;
  /* display: inline-block; */
}

input::placeholder {
  font-weight: 400;
  color: #bfbfbf;
  font-size: 14px;
  opacity: 0.5;
}

input:disabled {
  background-color: var(--grey) !important;
  cursor: not-allowed !important;
}

.goalsCard {
  display: grid;
  width: 100%;
  height: fit-content;
  grid-template-columns: repeat(3, 1fr);
}

.input-shorttextAtt:disabled {
  background-color: var(--grey) !important;
  cursor: not-allowed !important;
}

textarea:disabled {
  background-color: var(--grey) !important;
  cursor: not-allowed !important;
}

textarea::placeholder {
  font-weight: 400;
  color: #bfbfbf;
  font-size: 14px;
  opacity: 0.5;
  font-family: inherit;
}

.PhoneInputCountryIcon {
  border: none !important;
  height: 26px !important;
  width: 29px !important;
  padding: 0px 6px;
}

.PhoneInputCountryIcon--border {
  background-color: transparent !important;
  box-shadow: none !important;
}

.clockmui {
  height: 40px;
  width: 20px;
}

.iconClass {
  display: flex;
  align-items: center;
  position: absolute;
  right: 28px;
  background-color: white;
}

.timecustom {
  position: relative;
}

.emailcustom {
  position: relative;
}

.contpar {
  display: flex;
  align-items: center;
}

.label {
  font-weight: 500;
  font-size: 14px;
  color: #555555;
  padding-left: 10px;
}

.stydiv {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.inputSelect {
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02) !important;
  border-radius: 4px !important;
  width: calc(100% - 24px) !important;
  margin: 12px;
  padding: 12px;
  border: 1px solid transparent !important;
  box-sizing: border-box;
  outline: 0;
  min-height: 40px;
  flex: 1;
}

.fullWidth {
  width: 100% !important;
}

.payareaemp {
  line-height: 24px;
  display: flex;
  padding: 8px 6px;
  align-items: flex-start;
  flex-direction: column;
}

.react-select__option {
  background-color: #1296b0;
}

.colr {
  color: black !important;
}

.react-select__placeholder {
  padding-left: 6px !important;
  font-weight: 400;
  color: #bfbfbf;
  font-size: 14px;
  opacity: 0.5;
}

.flexyD {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.reddrop {
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

.reddrop:hover {
  border: 1px solid var(--primary) !important;
}

.react-select-container {
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02) !important;
  border-radius: 4px !important;
  width: calc(100% - 24px) !important;
  margin: 12px;
  border: 1px solid transparent !important;
  box-sizing: border-box;
  outline: 0;
  flex: 1;
  min-width: 150px;
}

.react-select-containerLD {
  background: #ffffff;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02) !important; */
  border-radius: 4px !important;
  width: calc(100% - 24px) !important;
  margin: 12px;
  border: 1px solid transparent !important;
  box-sizing: border-box;
  outline: 0;
  flex: 1;
  min-width: 150px;
}

.react-dropdown-select-dropdown {
  width: 100% !important;
  overflow-x: hidden !important;
}

.css-1wwdnnt-DropDown {
  width: 100% !important;
}

.react-select__control {
  border-radius: 4px !important;
  border-color: none !important;
  border-radius: none !important;
  border-style: none !important;
}

.react-select__control:focus {
  border: 1px solid var(--primary) !important;
}

.react-select__menu {
  border-radius: 4px !important;
  font-family: "Poppins";
  background-color: #1296b0;
}

.react-select__menu-portal {
  z-index: 100;
}

.react-select__multi-value__remove {
  background-color: var(--primary-hover);
}

.react-select__multi-value__label {
  background-color: var(--primary-hover);
}

.react-select__control:active {
  border: 1px solid var(--primary) !important;
}

.react-select__control:target {
  border: 1px solid var(--primary) !important;
}

.css-1a1v3ev-OptionComponent {
  background-color: var(--primary-hover) !important;
  color: black !important;
}

.css-1a1v3ev-OptionComponent .react-dropdown-select-option-remove {
  color: var(--primary);
}

.react-select__control--is-focused {
  border: 1px solid var(--primary) !important;
  border-color: 1px solid var(--primary) !important;
  box-shadow: none !important;
}

/* Table.css */
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 8px;
  text-align: start;
}

.custom-table th:first-child {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--PeopleSol-Dark-Grey, #555);
}

.custom-table td:first-child {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.custom-table th:nth-child(2) {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--essmss, #b69fb3);
}

.custom-table th:nth-child(3) {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--Tax, #8a88ed);
}

.custom-table th:nth-child(4) {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--ATS, #77a2c7);
}

.custom-table th:nth-child(5) {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--Secondery, #f7941d);
}

.custom-table th:nth-child(6) {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--Payroll, #da5db6);
}

.custom-table th:nth-child(7) {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--ld, #5bc4bf);
}

.custom-table th:nth-child(8) {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--reports-analytics, #a0a0cb);
}

.table-row {
  height: 50px;
  box-shadow: 0px 1.8944525718688965px 10px rgba(0, 0, 0, 0.04);
}

.table-row td:nth-child(2) {
  color: var(--essmss, #b69fb3);
  font-size: 14px;
  font-weight: 500;
}

.table-row td:nth-child(3) {
  color: var(--Tax, #8a88ed);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}

.table-row td:nth-child(4) {
  color: var(--ATS, #77a2c7);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}

.table-row td:nth-child(5) {
  color: var(--Secondery, #f7941d);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}

.table-row td:nth-child(6) {
  color: var(--Payroll, #da5db6);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}

.table-row td:nth-child(7) {
  color: var(--ld, #5bc4bf);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}

.table-row td:last-child {
  color: var(--reports-analytics, #a0a0cb);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}

.inputSelect:hover {
  border: 1px solid var(--primary-hover);
}

.react-select-container:hover {
  border: 1px solid var(--primary-hover) !important;
}

.react-select-containerLD:hover {
  border: 1px solid var(--primary-hover) !important;
}

.inputSelect:focus {
  border: 1px solid var(--primary) !important;
}

.react-select-container:focus {
  border: 1px solid var(--primary) !important;
}

.react-select-containerLD:focus {
  border: 1px solid #1296b0 !important;
}

.inputSelect:active {
  color: black;
  border: 1px solid#1296b0 !important;
}

.react-select-container:active {
  color: black;
  border: 1px solid#1296b0 !important;
}

.react-select-containerLD:active {
  color: black;
  border: 1px solid#1296b0 !important;
}

.inputSelect:target {
  color: #1296b0;
  border: 1px solid#1296b0;
}

.react-select-container:target {
  color: #1296b0;
  border: 1px solid#1296b0;
}

react-select-containerLD:target {
  color: #1296b0;
  border: 1px solid#1296b0;
}

.inputSelect:target-within {
  color: black;
  border-radius: 3px;
  border: 1px solid#1296b0;
}

.react-select-container:target-within {
  color: black;
  border-radius: 3px;
  border: 1px solid#1296b0;
}

.react-select-containerLD:target-within {
  color: black;
  border-radius: 3px;
  border: 1px solid#1296b0;
}

.inputSelect:focus-within {
  border: 1px solid#1296b0;
}

.react-select-container:focus-within {
  color: #1296b0;
  border: 1px solid#1296b0;
}

.react-select-containerLD:focus-within {
  color: #1296b0;
  border: 1px solid#1296b0;
}

.form-error {
  width: 100%;
  font-size: 11px;
  display: flex;
  align-items: center;
  padding-left: 11px;
  gap: 1px;
  color: red;
  font-family: "Poppins";
}

.WrapperCheck {
  padding-left: 10px;
  display: flex;
  align-items: center;
}

#checkbox {
  width: 18px;
  height: 18px;
  background: #ffffff;
  border: 0.75px solid #bfbfbf;
  border-radius: 3px;
  border: 1px solid transparent;
  accent-color: #1296b0;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
}

#checkbox:hover {
  border: 1px solid #62c5ce !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
}

#checkbox:focus {
  color: #1296b0;
  border: 1px solid#1296b0 !important;
}

#checkbox:active {
  color: #1296b0;
  border: 1px solid#1296b0 !important;
}

.label-check {
  font-size: 14px;
  font-weight: 500;
}

.logupload {
  display: initial;
  position: relative;
  bottom: 4px;
  right: 24px;
}

.progress {
  position: absolute;
  margin: 83px 17px 0 0;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-size: 10px;
  color: black;
  text-align: center;
  animation: blink 2s infinite;
}

.formcustomfooter {
  background: #faf9f8 !important;
}

.react-datepicker__time-list-item--selected {
  color: #1296b0 !important;
}

.react-datepicker {
  font-family: inherit !important;
  font-size: 0.8rem;
  background: #ffffff !important;
  color: #555555 !important;
  text-transform: uppercase;
  border: 0px;
  border-radius: 0;
  display: inline-block;
  position: relative;
}

.react-datepicker__header {
  text-align: center;
  /* width: 252.72px; */
  /* height: 25.87px; */
  color: #1296b0 !important;
  font-weight: 500;
  background: #d8f0f2 !important;
  border-bottom: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__start-date {
  background: #1296b0 !important;
}

.react-datepicker__today-button {
  background: #1296b0 !important;
  border-top: 0px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__current-month {
  color: #1296b0 !important;
  background: #d8f0f2 !important;
  /* width: 272.72px; */
  /* height: 26.87px; */
}

.react-datepicker__day--selected {
  background: #1296b0 !important;
}

.react-datepicker__input-container>input {
  color: var(--black);
  background-color: var(--white);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  margin: 12px;
  padding: 12px;
  border: 1px solid transparent !important;
  box-sizing: border-box;
  outline: 0;
  height: 40px;
  width: calc(100% - 24px);
}

.react-datepicker__input-container:hover>input {
  border: 1px solid var(--primary-hover) !important;
}

.react-datepicker__input-container:active>input {
  border: 1px solid var(--primary) !important;
}

.react-datepicker__input-container:focus>input {
  border: 1px solid var(--primary) !important;
}

.react-datepicker__input-container:focus-within>input {
  border: 1px solid var(--primary) !important;
}

.react-datepicker__input-container:target>input {
  border: 1px solid var(--primary) !important;
}

.react-datepicker__input-container:target-within>input {
  border: 1px solid var(--primary) !important;
}

.react-datepicker-popper {
  margin-left: 12px !important;
  z-index: 100 !important;
}

.watch {
  top: 53px;
  z-index: 4;
  background: white;
  position: absolute;
  border-radius: 4px;
  height: 253px;
  margin: 12px !important;
  width: calc(100% - 24px) !important;
}

.iconButton {
  position: absolute;
  right: 17px;
  top: 14px;
  cursor: pointer;
}

.react-datepicker__year-select {
  border: 2px solid var(--primary);
  color: var(--primary);
  border-radius: 5px;
}

.iconButtonEmail {
  position: absolute;
  right: 25px;
  top: 28px;
}

.iconButtonatt {
  position: absolute;
  top: 22px;
  right: 15px;
}

.btnup {
  border: none;
  background: transparent;
}

.radioCust {
  padding: 5px 5px;
}

.textAp {
  text-align: center;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-top: 18px;
}

.repeatedSectionContainer {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: fit-content;
  display: grid;
  min-height: 151px;
}

.tabsIn {
  margin: 0 !important;
  box-shadow: none !important;
  background-color: var(--form-background) !important;
  padding: 15px !important;
}

.radioFormik {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  gap: 24px;
  margin: 7px 11px;
  position: relative;
}

.radioFormikLD {
  display: flex;
  align-items: flex-start;
  padding: 20px 10px;
  gap: 24px;
  margin: 7px 11px;
  position: relative;
  flex-direction: column;
}

.padInner {
  padding: 0px 12px;
  gap: 5px;
}

.runpayF {
  gap: 12px;
  display: flex;
  padding-left: 24px;
  width: 616px;
  font-size: 13px;
  line-height: 23px;
  flex-direction: column;
}

.runPayYel {
  font-size: 13px;
  display: flex;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;
  border-radius: 8px;
  background-color: rgb(255, 244, 229);
}

.checkFormik {
  display: flex;
  align-items: center;
  padding: 12px 4px;
  gap: 6px;
  /* margin: 0px 4px; */
  position: relative;
  margin-left: 12px;
}

.checkFormik>input {
  flex-shrink: 0;
}

.clipContainer {
  position: absolute !important;
  right: 4px;
  top: 3px;
  cursor: pointer;
}

.clipContainerLD {
  position: absolute !important;
  left: -13px;
  top: -9px;
  cursor: pointer;
}

.clipContainers {
  position: absolute !important;
  right: 6px;
  top: 10px;
  cursor: pointer;
}

.clipContainerHolder {
  position: relative;
  border: 1px solid #bfbfbf;
  height: 35px;
}

.clipContainerHolder>label {
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #bfbfbf;
  padding-left: 12px;
}

.PhoneInputInput {
  color: var(--black);
  background-color: var(--white);
  margin: 7px;
  padding: 9px;
  border: none;
  width: calc(100% - 24px);
  flex: 1 1;
  outline: none !important;
}

.PhoneInput {
  margin: 12px;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 4px;
  border: 1px solid transparent;
  height: 40px;
}

.PhoneInput:hover {
  border: 1px solid var(--primary-hover);
}

.PhoneInput:focus {
  border: 1px solid var(--primary);
}

.createProjectContainer {
  background-color: white;
  max-height: 90%;
  min-height: 30%;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  height: fit-content;
  background: #faf9f8 !important;
  max-width: 50%;
  width: 50%;
  contain: content;
}

.createProjectContainerbig {
  background-color: white;
  max-height: 90%;
  min-height: 30%;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  height: fit-content;
  background: #faf9f8 !important;
  max-width: 50%;
  width: 70%;
  contain: content;
}

.btnForm {
  margin: 12px;
  height: 65px;
  width: 85px;
  align-items: center;
  height: 34px;
  background: #ffffff;
  border: 1.5px solid #1296b0;
  border-radius: 3px;
  color: #1296b0;
}

.gridPend {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
}

.buttongoal {
  margin: 4px 4px;
  outline: 0;
  border: 0;
  border-radius: 6px;
  font-size: 12px;
  padding: 6px 12px;
  text-align: center;
  height: fit-content;
  width: fit-content;
  font-family: "Poppins";
}

.formfoot {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  margin: 0 -12px;
  height: 65px;
  align-items: center;
  background: #faf9f8 !important;
}

/* .btnArr{
  display: flex;
  padding: 0px 28px 0px 0px;

} */
.css-bsph2u-MuiTableCell-root {
  border: none !important;
}

.css-1ndpvdd-MuiTableCell-root {
  border: none !important;
}

.SubmitBookingTable .css-1ndpvdd-MuiTableCell-root {
  border: 1px solid rgba(189, 189, 189, 0.36) !important;
}

.ReimbursementTable .css-1ndpvdd-MuiTableCell-root {
  border: 1px solid rgba(189, 189, 189, 0.36) !important;
}

/* 
.SubmitBookingTable .css-apqrd9-MuiTableBody-root>td:first-child {
  padding: 0px !important;
} */

.goalTeam {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 9px 10px;
  background-color: rgb(255, 244, 229);
  color: var(--secondary);
}

.createPageContainer {
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  backdrop-filter: blur(10px);
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute !important;
  width: 100%;
  z-index: 8;
}

.bgCreate {
  justify-content: center;
  position: absolute !important;
  z-index: 8;
  height: 100%;
  width: 100%;
  mix-blend-mode: normal;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: white;
  position: relative;
  gap: 0;
  overflow: hidden;
}

.createFormCont {
  background: #faf9f8 !important;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  height: 90%;
  width: 75%;
  contain: content;
}

.createHeader {
  background-color: white;
  height: 47px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.StepperContainer-0-2-1 {
  font-family: inherit !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 6 !important;
  background-color: var(--form-background) !important;
  border-bottom: 1px solid var(--grey) !important;
}

.react-datepicker__triangle {
  position: absolute;
  top: 1px;
  left: -42px !important;
  transform: translate(206px, 0px);
  border-bottom-color: var(--primary-hover);
}

.react-datepicker__month-container {
  font-family: "Poppins" !important;
}

/* react-dropdown-select-dropdown react-dropdown-select-dropdown-position-bottom css-1vfa9w0-DropDown e1qjn9k91 */
.react-datepicker__year-select {
  font-size: 14px;
  text-align: center;
  height: 22px;
  width: 74px;
}

.headingForm {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  padding: 3px;
  display: flex;
  align-items: center;
  border-bottom: 1.75px solid #b5b5b5;
  color: #1296b0;
  height: 24px;
  margin: 12px 12px 6px 12px;
}

.css-1o60ie7-DropDown {
  width: 100% !important;
}

.bodyContainer {
  height: calc(100% - 49px);
  overflow: auto;
  overflow-x: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.myStep {
  max-width: 90 !important;
}

.btnfinish {
  background-color: #1296b0 !important;
  color: white !important;
  background: #1296b0;
  box-shadow: 0px 4px 8px rgba(18, 150, 176, 0.2),
    inset 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.css-1xe4f1r-DropDown {
  width: 100% !important;
}

.createFooterButtonForm1 {
  margin-right: 17px;
  color: #f71d1d;
  min-width: 84px;
  font-weight: 400;
  font-size: 15px;
  background: #ffffff;
  width: 112px;
  height: 34px;
  box-shadow: 0px 4px 8px rgba(207, 207, 207, 0.4),
    inset 0px 1px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  border: transparent;
}

.createFooterButtonForm2 {
  font-weight: 400;
  font-size: 15px;
  width: 123.49px;
  height: 34px;
  color: #ffffff;
  background: #1296b0;
  box-shadow: 0px 4px 8px rgba(18, 150, 176, 0.2),
    inset 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  border: transparent;
}

.d-flex {
  position: sticky;
  top: 0;
  background-color: #faf9f8;
  z-index: 5;
  border-bottom: 1px solid var(--grey);
}

.stepperCont>form> :last-child {
  position: sticky;
  bottom: 0;
  z-index: 5;
  background-color: #faf9f8;
}

.formikComponentContainer {
  position: relative;
  padding: 0;
}

.formikComponentContainesr {
  position: relative;
  padding: 0 0 18px 0;
}

.time {
  background: white;
  margin-left: 12px !important;
  width: calc(100% - 24px) !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  left: -10px !important;
}

.css-1aa5qj0-MuiInputBase-root-MuiInput-root {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  border: 1px solid transparent;
  background: white;
}

.css-1aa5qj0-MuiInputBase-root-MuiInput-root:hover {
  border: 1px solid var(--primary-hover);
}

.css-1aa5qj0-MuiInputBase-root-MuiInput-root:focus {
  border: 1px solid var(--primary);
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  margin-left: 12px !important;
}

#input-time::placeholder {
  padding-left: 12px !important;
  font-weight: 400;
  color: #bfbfbf;
  font-size: 14px;
  opacity: 0.5;
}

.indexAc {
  flex: 1;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 17px;
  font-weight: 500;
}

.actionItem {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border: 1px solid #f1f2f2;
  border-radius: 8px;
  flex: 3;
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
  padding: 2px 20px 20px 20px;
}

.itemsAct {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  line-height: 30px;
  flex-wrap: wrap;
}

.smallContentfooterButtonHolder {
  display: flex;
  justify-content: flex-end;
  background-color: var(--form-background);
  height: 65px;
  align-items: center;
  margin-top: 24px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2rem);
  }
}

.menu {
  animation: fadeIn 0.2s ease-in-out;
}

.menu--close {
  animation: fadeOut 0.2s ease-in-out;
}

.disabledInput {
  background-color: var(--grey) !important;
  cursor: not-allowed !important;
}

.centertd {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  font-weight: 500;
  color: white;
  font-size: 15px;
  height: 119px;

  border-radius: 6px;

  min-width: 120px;
  background-color: #5bc4bf;
}

.formSelfReview {
  margin-top: 12px;
  background: #d4f2f1;
  border-radius: 6px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.borderDot {
  border-top: 1px dotted #ddd;
  border: 1px solid transparent;
  background-image: linear-gradient(to right,
      rgb(128 126 126 / 57%) 25%,
      transparent 25%),
    linear-gradient(to bottom, rgb(128 126 126 / 32%) 25%, transparent 25%);
  background-size: 5px 1px;
  background-repeat: repeat-x, repeat-y;
}

.badgePms {
  background-color: red;
  border-radius: 50%;
  padding: 0px 7px;
  box-shadow: 4px 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  text-align: center;
  margin: 0px 0 0px 3px;
  font-weight: 500;
}

.ratingCar {
  border-radius: 8px;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  display: flex;
  border-radius: 6px;
  font-size: 12px;
}

.ratingSelf {
  width: fit-content;
  gap: 24px;
  border-radius: 5.07659px;
  display: flex;
  align-items: start;
  background-color: #d4f2f1;
  justify-content: space-between;
  font-family: "Poppins";
  padding: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.yz {
  flex: none;
  padding: 16px;
  width: 22%;
}

.headingSelf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #5bc4bf;
  height: 35px;
  padding: 8px;
  color: white;
  border-radius: 5.08332px 5.08332px 0 0;
}

.notesSelf {
  width: fit-content;
  flex: 1;
  border-radius: 5.08332px;
  background: #d4f2f1;
  margin-top: 12px;
  min-height: 135px;
}

.detailSelf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.btnSelf {
  padding: 6px 6px;
  outline: 0;
  border: 0;
  margin: 0px 6px;
  font-weight: 500;
  color: white;
  background: #77a2c7;
  box-shadow: inset 0px 3.35228px 8.38071px rgba(0, 0, 0, 0.15);
  border-radius: 2.51421px;
  font-size: 12px;
  text-align: center;
  min-width: 50px;
  font-family: "Poppins";
}

.approvedGoals {
  padding: 8px;
  border-radius: 6px;
  color: rgb(13, 181, 60);
  background: rgb(231, 248, 236);
  margin: 6px 12px;
}

.pendingGoals {
  padding: 8px;
  border-radius: 6px;
  color: #f7941d;
  background: #fff4e5;
  margin: 6px 12px;
}

.rejectedGoals {
  padding: 8px;
  border-radius: 6px;
  background-color: rgb(253, 210, 210);
  color: rgb(247, 29, 29);
  margin: 6px 12px;
}

.BessHolderas {
  height: 75px;
  width: 50px;
}

.reviewNotes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.formcustomcompanycar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3px;
  padding-top: 24px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.scrollable-body {
  max-height: 70vh;
  overflow-y: auto;
}

.responsive-table th {
  background-color: #faf9f8;
  position: sticky;
  top: 0;
  z-index: 2;
}

.responsive-table tbody tr:last-child td {
  border-bottom: none;
  /* Remove border for last row */
}

.employteam {
  height: 100%;
  background: white !important;
}

.responsive-table tfoot tr th {
  background-color: #faf9f8;
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.footerPaginationContainer {
  position: sticky;
  bottom: 0;
  background-color: #faf9f8;
  z-index: 2;
  border-top: 1px solid #ddd;
}

.footerPagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.paginationControls {
  display: flex;
  align-items: center;
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  .footerPagination {
    flex-direction: column;
    padding: 8px 16px;
  }

  .paginationControls {
    margin-top: 8px;
  }
}

.parentContainer {
  position: relative;
  height: 100vh;
  /* Set an appropriate height for the parent container */
}

/* Style the table and make the header sticky */
.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.responsive-table th {
  background-color: #faf9f8;
  position: sticky;
  top: 0;
  z-index: 2;
}

.responsive-table tbody tr:last-child td {
  border-bottom: none;
  /* Remove border for last row */
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.responsive-table th {
  background-color: #faf9f8;
  position: sticky;
  top: 0;
  z-index: 2;
}

.responsive-table tbody tr {
  height: 50px;
  /* Set the desired height for each row */
}

.responsive-table td {
  white-space: nowrap;
  /* Prevent text wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Show ellipsis (...) for overflowing text */
}

.responsive-table tbody tr:last-child td {
  border-bottom: none;
  /* Remove border for last row */
}

.responsive-table th:nth-child(1) {
  width: 10%;
  /* Set the desired width for the first column */
}

.responsive-table th:nth-child(2) {
  width: 20%;
  /* Set the desired width for the second column */
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.responsive-table th {
  background-color: #faf9f8;
  position: sticky;
  top: 0;
  z-index: 2;
}

.responsive-table tbody tr {
  height: 50px;
  /* Set the desired height for each row */
}

.responsive-table td {
  word-wrap: break-word;
  /* Wrap text within cells */
  overflow: hidden;
}

.responsive-table tbody tr:last-child td {
  border-bottom: none;
  /* Remove border for last row */
}

.logbook {
  position: relative;
  margin: 20px auto;
  width: 100%;
}

.logbook-item {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  margin: 20px 0;
  position: relative;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.logbook-serial {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00a0ff;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  font-size: 18px;
}

.logbook-content {
  flex-grow: 1;
  margin-left: 20px;
}

.logbook-date {
  font-size: 14px;
  font-weight: bold;
  color: #555;
}

.logbook-comment {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

.logbook-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 16px;
  width: 2px;
  height: 100%;
  background-color: #ccc;
}

.logbook-item:first-child:before {
  display: none;
}

.logbook-item.active .logbook-serial {
  background-color: #fff;
  color: #00a0ff;
  border: 2px solid #00a0ff;
}

/* Styles for the overlay that covers the background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  /* Semi-transparent background */
  z-index: 100;
  /* Ensure the overlay is on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for the popup container */
.popuplogout {
  background-color: #fff;
  border-radius: 8px;
  /* margin: auto; */
  flex-direction: column;
  gap: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

/* Styles for the popuplogout message */
.popuplogout-content {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 17px;
  margin: 0;
}

.progrssPercent {
  padding-left: 1px;
  font-size: 11px;
  font-weight: 400;
  color: var(--PeopleSol-Dark-Grey, #555);
}

/* Style for the close button */
/* Hover effect for the close button */
.topHeader {
  height: 130px;
  background-color: #d8f0f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.picProf {
  position: absolute;
  top: 20;
  left: 5;
}

.accordion {
  width: 100%;
}

.accordion-header {
  padding: 6px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
}

.accordion-content {
  padding: 10px;
}

.icon {
  font-size: 20px;
  font-weight: bold;
}

.open .icon {
  transform: rotate(180deg);
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.profper {
  background: var(--Secondery, #f7941d);
  border-radius: 6.5px;
  padding: 1px 5px;
  font-size: 12px;
  color: white !important;
}

.restClas {
  padding: 4px;
  display: flex;
  height: fit-content;
  gap: 12px;
  width: 100%;
  align-items: center;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  background: inherit;
}

/* LoadingOverlay.css */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.overlay {
  position: fixed;

  top: 0;

  left: 0;

  width: 100%;

  height: 100%;

  z-index: 100000000;
}

._1oYOS {
  border: none;
}

.overlay.loaded .overlayContent {
  opacity: 0;

  margin-top: -15px;
}

.overlay .overlayContent {
  position: relative;

  width: 100%;

  height: 100%;

  display: flex;

  justify-content: center;

  align-items: center;

  flex-direction: column;

  transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);

  background: #fff;
}

.SubmitBookingTable .input-shorttext {
  border: 1px solid #dbeaed !important;
}

.SubmitBookingTable .input-shorttextAtt {
  border: 1px solid #dbeaed !important;
}

/* .TravelTableMulti td {
  padding: 0px !important;
} */
.TravelTableMulti>thead>tr>td:nth-child(2) {
  padding: 0px !important;
}

.TravelTableMulti>tbody>tr>td:nth-child(2) {
  padding: 0px !important;
}

.TravelTableMultiIn td {
  padding: 10px !important;
}
.empProfileDetailsMainContainer {
    width: 23%;
    border-right: 1px solid #d8f0f2;
    /* overflow-y: auto; */
  }
  .empProfileHeaderContainer {
    height: 64px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
  }
  .empProfileHeaderWrapper {
    padding-left: 20px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .empProfileHeaderContainer svg {
    width: 20px;
    cursor: pointer;
    margin-right: 20px;
  }
  .empProfileHeaderContainer span {
    color: #1296b0;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding-left: 15px;
  }
  
  .empProfileDetailsMainWrapper {
    height: 100%;
    overflow-y: auto;
    flex-grow: 1 !important;
  }
  .empProfileDetailsMainWrapper::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .empProfileImageContainer {
    width: 100%;
    border-bottom: 1px solid #d8f0f2;
    padding-bottom: 20px;
  }
  .empProfileImageWrapper {
    display: flex;
    justify-content: center;
    padding: 30px 0 5px 0;
  }
  .empProfileImageContainer .empProfileImageWrapper svg{
    mix-blend-mode: darken;
   position: relative;
   right: -60px;
    
  }
  .empProfileImage {
    background: url("https://s3-alpha-sig.figma.com/img/d218/110b/54375111eb4647c0f179786ae58e442c?Expires=1692576000&Signature=MQdWQPMW5rMZM2c8B0Zw41TDK06yxZ-7jwOiwF-dgarAG343i~Vu~vkxa8rHpoAqLnI0d528KujnKcdqkhBVGM8Evp-Kok4VWJ2g9COnmKMEFS4slB5C0BCc7eOAP~NMnI~UKZ4DjHVBcAT2ZIUwd3GFFV2rtPhTYZ6341HhxNU6dhY~7oDbtR9nUwuIftl-Mkdxg5ywb1njrTF7XEpT-CttkoEYTuA6H3h8RhAhwHtZtO-Hk7FsvoWWaxCNlcSotW35Xsz-~esSpjOmHc3aO7PFEdkFGKO5gH3kDkUako4PZw8x4mCfP2rMYSa0qVb5JxYkeO1mfCBU40cISxbxJA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    width: 102.879px;
    height: 102.879px;
    border-radius: 1000px;
    position: relative;
    top: 25px;
    left: -70px;
    /* z-index: -1; */
  
  }
  .imgText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .imgText h4 {
    font-size: 13px;
    font-weight: 500;
    color: #1296b0;
  }
  .imgText span {
    font-size: 11px;
    font-weight: 500;
  }
  
  .EmpProfileAttachContainer{
    padding: 15px 24px;
  }
  .EmpProfileAttachContainer .EmpProfileAttachWrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .EmpProfileAttachContainer .EmpProfileAttachWrapper .media{
    color: #DE64A1;
    background-color:#FFEEFA ;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
  }
  .EmpProfileAttachContainer .EmpProfileAttachWrapper .media span{
    padding-left: 10px;
  }
  
  .EmpProfileAttachContainer .EmpProfileAttachWrapper .links{
    color: #1296B0;
    background-color:#D8F0F2 ;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
  }
  .EmpProfileAttachContainer .EmpProfileAttachWrapper .links span{
    padding-left: 10px;
  }
  
  .EmpProfileAttachContainer .EmpProfileAttachWrapper .docs{
    color: #8A88ED;
    background-color:#F0F0FF ;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
  }
  .EmpProfileAttachContainer .EmpProfileAttachWrapper .docs span{
    padding-left: 10px;
  }
  
  .empProfileAboutContainer{
    padding: 0px 30px 10px 30px; 
  }
  .empProfileAboutContainer .empProfileAboutWrapper{
    border: 1px solid #d8f0f2;
    border-radius: 5px;
    padding: 15px ;
  }
  .empProfileAboutContainer .empProfileAboutWrapper .aboutTextWrapper1, 
  .empProfileAboutContainer .empProfileAboutWrapper .aboutTextWrapper2, 
  .empProfileAboutContainer .empProfileAboutWrapper .aboutTextWrapper3{
    display: grid;
    grid-template-columns: repeat(2 ,1fr);
    align-items: center;
    padding: 10px 0;
  }

  
  .aboutTextWrapper1 .empId hr, 
  .aboutTextWrapper1 .department hr,
  .aboutTextWrapper2 .role hr, 
  .aboutTextWrapper2 .mobileno hr, 
  .aboutTextWrapper3 .email hr
  {
    border: 1px solid #1296b0;
    width: 30px;
    border-radius: 10px;
  }
  .aboutTextWrapper1 .empId span, 
  .aboutTextWrapper1 .department span,
  .aboutTextWrapper2 .role span, 
  .aboutTextWrapper2 .mobileno span, 
  .aboutTextWrapper3 .email span
  {
    font-size: 11px;
    color: #555;
  }
  .aboutTextWrapper1 .empId p, 
  .aboutTextWrapper1 .department p,
  .aboutTextWrapper2 .role p, 
  .aboutTextWrapper2 .mobileno p, 
  .aboutTextWrapper3 .email p
  {
    font-size: 13px;
    color: #555;
    font-weight: 500;
  }
  
  .empProfileGroupContainer{
    padding: 20px 30px 0px 30px;
  }
  .empProfileGroupContainer p{
    padding-bottom: 7px;
  }
  .empProfileGroupContainer .empProfileGroupWrapper {
    border: 1px solid #d8f0f2;
    padding: 10px;
    padding-bottom: 30px;
  }
  .empProfileGroupContainer .empProfileGroupWrapper .creteNewGroup{
    display: flex;
    align-items: center;
  }
  .creteNewGroup {
    background-color: rgba(216, 240, 242, 0.4);
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 5px;
  }
  .creteNewGroup p{
    color: #000;
    font-size: 15px;
    padding-left: 10px;
  }
  .goupList{
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  .goupList .imageContainer{
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .goupList .imageContainer img{
    width: 100%;
    height: 100%;
    border-radius: 1000px;
  }
  .goupList .textContainer h3{
    color: #555;
    font-size: 13px;
  }
  .goupList .textContainer p{
    font-size: 11px;
    color: #555;
    padding-bottom: 0;
  }
  
  .empProfileBlockContainer {
    padding: 30px;
    padding-bottom: 100px;
  }
  .empProfileBlockContainer .empProfileBlockWrapper{
    display: flex;
    align-items: center;
  }
  .empProfileBlockContainer .empProfileBlockWrapper .blockText{
   font-size: 14px;
   color: #F71D1D;
   font-weight: 700;
   padding-left: 10px;
   margin-bottom: 4px;
  }
.tabless-bordered th {
  border-top: 1px solid #ddd !important;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd !important;
}


.tabless th,
.tabless td {
  padding: 12px !important;
}

.tabless {
  font-size: 13px !important;
  color: #4e5e6a;
  width: 100%;
}

.tabless th {
  padding: 12px !important;
  letter-spacing: 0 !important;
}

.tabless-Fieldarraycombo .css-1ndpvdd-MuiTableCell-root {
  border: 1px solid rgba(189, 189, 189, 0.36);
}



.tabself-bordered th {
  border-top: 1px solid #ddd !important;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd !important;
}

.tabself-bordered th {
  border-radius: 5.07659px;
}

.tabself th,
.tabself td {
  border-radius: 4px;
  padding: 12px 6px !important;
  border-radius: 5.07659px;
}

.selfParent {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  height: calc(100% - 24px);
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 12px;
  margin: 12px;
  background-color: var(--white);
}

.trStyles {
  transition: 0.3s ease;
}

.trStyles {
  box-shadow: 0px -1.89445px 10px rgba(0, 0, 0, 0.04);
  ;
}

.tabself {
  font-size: 13px !important;
  color: #4e5e6a;
  width: 100%;
}

.tabself th {
  padding: 12px !important;
}

.tabless-bordered td:last-child {
  border-right: 1px solid #ddd !important;
}

.tabless-bordered th:last-child {
  border-right: 1px solid #ddd !important;
}

.tabless-bordered td {
  border-bottom: 1px solid #ddd !important;
  border: 1px solid #ddd !important;
}

.tabl td {
  border-bottom: 1px solid #ddd !important;
  border: 1px solid #ddd !important;
}

.desExit {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 1.97201px 14.7901px rgba(0, 0, 0, 0.06);
  border-radius: 4.16376px;
  padding: 24px;
  flex-direction: column;
  text-decoration: none;
  gap: 2px;
  align-items: center;
  line-height: 28px;
  text-decoration: none;
  max-width: 220px;
}

/* .PaddingAStyle {
  padding: 0px !important;
} */
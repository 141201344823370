.App-map{
    height: 100%;
    width: 100%;
}

.officeLocationPopupBox1{
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100%;
    top: 30px;
    display: flex;
    align-items: center;
    left: 0;
    justify-content: center;
    z-index: 999;
  }
  .officeLocationPopupBox2{
    display: flex;
    flex-direction: column;
    contain: content;
    position: relative;
    background: #FAF9F8;
    border-radius: 8px;
    border: 1px solid #999;
    border-radius: 6px;
    max-height: 90%;
    min-width: 80%;
    min-height: 80%;
    max-width: 95%;
  }
  .location-search-input{
    width: 400px;
    /* padding: 10px 10px; */
    outline: none;
    border: none;
  }
  .officeLocationPopupContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 25px;
  }
  .officeLocationPopupContainer .googleSearchContainer{
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
  }
  .searchAndMapContainer{
    display: flex;
    flex-direction: column;
    width: 55%;
    background-color: #fff;
    /* padding-top: 15px; */
  }
  .officeLocationPopupContainer .googleSearchContainer span{
    margin-right: 20px;
    padding-left: 10px;
    font-weight: 500;
    color: #1296b0;
    /* white-space: nowrap; */
  }
  .officeLocationPopupContainer .officeLocationContainer{
    width: 100%;
    display: flex;
  }
  .officeLocationPopupContainer .officeLocationContainer .officeLocationMapContainer{
    /* width: 60%; */
    height: 500px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
  }
  .officeLocationPopupContainer .officeLocationContainer .officeLocationEmployeeContainer{
    display: flex;
    flex-grow: 1;
    width: 45%;
    flex-direction: column;
    margin-left:30px;
  }
  
  .officeLocationPopupContainer .officeLocationContainer .officeLocationEmployeeContainer .officeLocationEmployeeWrapper{
   background-color: #fff;
  }
  .officeLocationPopupContainer .officeLocationContainer .officeLocationEmployeeContainer .officeLocationEmployeeWrapper .officeLocationEmployeeBox{
    padding: 10px 30px 30px 30px;
    
  }
  .officeLocationPopupContainer .officeLocationContainer .officeLocationEmployeeContainer .officeLocationEmployeeWrapper .officeLocationEmployeeBox .empSearchContainer{
    border: 0.665px solid var(--Grey-2, #E6E6E6);
    box-shadow: 0px 1.77434px 1.77434px 0px rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 30%; */
  }
  .officeLocationPopupContainer .officeLocationContainer .officeLocationEmployeeContainer .officeLocationEmployeeWrapper .officeLocationEmployeeBox input{
    border: none;
    outline: none;
    padding: 5px;
  }
  .officeLocationPopupContainer .officeLocationContainer .officeLocationEmployeeContainer .officeLocationEmployeeWrapper .officeLocationEmployeeHeading{
    width: 100%;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  }
  .officeLocationPopupContainer .officeLocationContainer .officeLocationEmployeeContainer .officeLocationEmployeeWrapper .officeLocationEmployeeHeading h3{
    padding: 17px 30px;
    color: var(--PeopleSOl-Primery, #1296B0);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.32px; 
  }
  
  .officeLocationPopupContainer .officeLocationContainer .officeLocationEmployeeWrapper .officeLocationEmployeeBox .officeLocationEmployeeList{
    display: flex;
    align-items: center;
    /* margin-top: 20px; */
  }
  .officeLocationEmployeeList .availableEmployeeList .availableEmpBody,
  .officeLocationEmployeeList .selectedEmplList .selectedEmpBody
  {
    border: 0.665px solid var(--Grey-2, #E6E6E6);
    border-radius: 5px;
  }
  .officeLocationEmployeeList .availableEmployeeList ul,
  .officeLocationEmployeeList .selectedEmplList ul
  {
    padding: 15px;
  }
  .officeLocationEmployeeList .availableEmployeeList,
  .officeLocationEmployeeList .availableSearch
  {
    margin-right: 15px !important;
    /* width: 50%; */
  }
  .officeLocationEmployeeList .selectedEmplList,
  .officeLocationEmployeeList .selectedSearch
   {
    margin-left: 15px !important;
    /* width: 50%; */
  }
  .officeLocationEmployeeList .availableEmployeeList .availableEmpBody .availableEmpHeader,
  .officeLocationEmployeeList .selectedEmplList .selectedEmpBody .selectedEmpHeader
  {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    border-bottom: 0.665px solid var(--Grey-2, #E6E6E6);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  }
  .officeLocationEmployeeList .availableEmployeeList .availableEmpBody .availableEmpHeader .selectAllCheckbox,
  .officeLocationEmployeeList .selectedEmplList .selectedEmpBody .selectedEmpHeader .selectAllCheckbox
  {
    display: flex;
    align-items: center;
  }
  .officeLocationEmployeeList .availableEmployeeList .availableEmpBody .availableEmpHeader input[type="checkbox"]  
  {
    margin-bottom: 4px;
    margin-left: 15px;
  }
  .officeLocationEmployeeList .selectedEmplList .selectedEmpBody .selectedEmpHeader input[type="checkbox"]{
    margin-bottom: 4px;
    margin-left: 15px;
  }
  
  .officeLocationEmployeeList .availableEmployeeList .availableEmpBody .availableEmpHeader h4,
  .officeLocationEmployeeList .selectedEmplList .selectedEmpBody .selectedEmpHeader h4
  {
    font-size: 14px;
    padding: 10px 15px;
    margin-bottom: 0;
  }
  
  .officeLocationEmployeeList .availableEmployeeList .availableEmpBody ul li,
  .officeLocationEmployeeList .selectedEmplList .selectedEmpBody ul li
  {
    list-style: none;
    padding: 7px 0;
    /* white-space: nowrap;  */
    display: flex;
  }
  .officeLocationEmployeeList .availableEmployeeList .availableEmpBody ul li input,
  .officeLocationEmployeeList .selectedEmplList .selectedEmpBody ul li input
  {
    margin-right: 10px;
  }
  .officeLocationEmployeeList .availableEmployeeList .availableEmpBody ul ,
  .officeLocationEmployeeList .selectedEmplList .selectedEmpBody ul 
  {
    height: 230px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .officeLocationEmployeeList .availableEmployeeList button,
  .officeLocationEmployeeList .selectedEmplList button
  {
    color: #fff;
    margin-top: 15px;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  }
  .officeLocationEmployeeList .availableEmployeeList button
  {
    padding: 6px 30px;
    background-color: #1296B0;
  }
  .officeLocationEmployeeList .selectedEmplList button
  {
    padding: 6px 22px;
    background-color: #F71D1D;
  }
  
  .officeLocationEmployeeContainer .officeLocationFooterBtn{
    display: flex;
    justify-content: end;
    padding-top: 40px;
    
  }
  .officeLocationEmployeeContainer .officeLocationFooterBtn button{
    border: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 10px 15px;
    font-weight: 500;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  }
 
  .officeLocationEmployeeContainer .officeLocationFooterBtn .officeLocationCancelBtn
  {
    color: #F71D1D;
    margin-right: 20px;
    border-radius: 3px;
    background: var(--White, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(207, 207, 207, 0.40), 0px 1px 10px 0px rgba(0, 0, 0, 0.05) inset;
  }
  .officeLocationEmployeeContainer .officeLocationFooterBtn .officeLocationCreateBtn
  {
    background-color: #1296B0;
    color: #fff;
  }
 
  .selectedEmplList svg{
    margin-right: 10px;
  }
  .availableEmployeeList svg{
    margin-left: 10px;
  }
.officeLocationContentHolder{
    padding: 12px; 
    font-size: 14px;
    display: block;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}
.ofcSearchWrapper{
 position: relative;   
 width: 100%;
}
.ofcSearchWrapper input{
 border-bottom: 1px solid rgb(218, 218, 218);   
 width: 100%;
 padding-bottom: 7px;
}
.autocomplete-dropdown-container{
    position: absolute;
    z-index: 999;
    padding: 10px;
}
.gm-style-mtc {
    display: none;
  }

  .suggestion-item--active{
    padding: 7px 15px;
    background-color: #f4fdff;
  }
.suggestion-item{
  padding: 7px 15px;
}


.selectMsgChkbox{
  /* margin-right: 10px; */
}
.selectInput[type="checkbox"]:before{
  position: relative;
  display: block;
  content: "";
  background: #FFF;
}
.selectInput[type="checkbox"]:after{
  position: relative;
  display: block;
  left: 2px;
  top: -11px;
  width: 7px;
  height: 7px;
  content: "";
  border: 1px solid #1296B0;
  border-radius: 3px;
  background-color: #FFF;
  background-repeat: no-repeat;
  background-position:center;
}
.selectInput[type="checkbox"]:checked:after{
  background-image:  url('../../../Assets/RightCheck.svg'), linear-gradient(135deg, #1296B0 0%,#1296B0 100%);
  border: none;
}
.selectInput[type="checkbox"]:not(:disabled):checked:hover:after{
  background-image:  url('../../../Assets/RightCheck.svg') linear-gradient(135deg, #1296B0 0%,#1296B0 100%);
  border: none;
}
.selectInput[type="checkbox"]:not(:disabled):hover:after{
  background-color:#c9c9c9;  
  border: none;
}
.selectInput.large[type="checkbox"]:before{
  width: 20px;
  height: 20px;
}
.selectInput.large[type="checkbox"]:after{
  top: -20px;
  width: 16px;
  height: 16px;
}
button:disabled{
  background-color: #999;
}
.inputOfficeLocation {
    width: 100%;
    padding: 0 30px;
    margin: 10px 0;
}
.inputOfficeLocation input{
    width: 100%;
    border: none;
    outline: none;
    border: 1px solid rgb(245, 245, 245);
    padding: 5px 10px;
}
.inputOfficeLocationContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
}
.inputOfficeLocationContainer .inputOfficeLocationWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    
}
.inputOfficeLocationContainer h5 {
    white-space: nowrap;
    font-size: 14px;
    padding-left: 10px;
    color: #1296B0;
    margin: 10px 0;
}
.officeLocationEmployeeListBox{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.inputSearchContainer{
  width: 78%;
}
.empSearchContainer  svg{
  margin-right: 10px;
}
/* .getEmployeeName{
  text-wrap: wrap;
} */

.inputOfficeLocationWrapper h5 sup,
.googleSearchContainer span sup{
  color: red;
}



  /* @media screen and (max-width: 1280px) {
    .officeLocationMapContainer{
        width: 70%;
      }
  }
  @media screen and (max-width: 1200px) {
    .officeLocationMapContainer{
        width: 70%;
      }
  }
  @media screen and (max-width: 1024px) {
    .officeLocationMapContainer{
        width: 70%;
      }
  } */

.ChatConversationRightContainer {
  width: 75%;
  font-family: "Poppins", sans-serif !important;
}

.chatContainerBg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.chatContainerBg1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 428.998px;
  height: 417.318px;
  border-radius: 1000px;
  transform: translate(-40%, -40%);
  background: linear-gradient(202deg,
      #13a9c7 10.93%,
      rgba(255, 255, 255, 0.2) 100%);
}

.EmojiPickerReact {
  position: fixed !important;
  bottom: 65px !important;
  left: 363px !important;
}

.message {
  display: flex;
  padding: 9px 16px 9px 24px;
  align-items: flex-start;
  gap: 14px;
}

.dropdowncol div:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.proftime {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imagePreviewContainer {
  position: relative;
  padding: 12px;
  background-color: rgba(216, 240, 242, 0.4);
  font-size: 13px;
  height: calc(100% - 62px);
  overflow-x: hidden;
  font-size: 13px;
  background: rgba(216, 240, 242, 0.4);
}

.message-content {
  /* max-width: 70%; */
  padding: 8px 12px;
  /* border-radius: 6px 0px 6px 6px; */
  background-color: white;
  color: var(--primary);
  max-width: calc(100% - 45px);
}

.timestamp {
  margin-top: 4px;
  color: var(--PeopleSol-Dark-Grey, var(--black, #555));
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 410;
}

.sent {
  flex-direction: row-reverse;
}

.received {
  flex-direction: row;
}

.sent .message-content {
  background-color: var(--primary);
  color: white;
}

.tab-container {
  margin: 12px 0;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  width: 94%;
  text-align: center;
  background: #d8f0f2;
}

.tab-button {
  color: #555555;
  font-size: 12px;
  background-color: #d8f0f2;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  margin-right: 10px;
}

.tab-button.active {
  background: var(--primary);
  border: 0;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 12px;
  min-width: 100px;
  outline: 0;
  padding: 6px 12px;
  text-align: center;
  color: white;
}

.tab-content {
  width: 100%;
}

.tab {
  display: none;
}

.tab-content .tab {
  display: block;
}

.chatContainerBg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 634.256px;
  height: 616.988px;
  border-radius: 1000px;
  transform: translate(-40%, -40%);
  background: linear-gradient(197deg,
      #13a9c7 0%,
      rgba(255, 255, 255, 0.2) 100%);
}

.chatContainerBg3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 827.829px;
  height: 845.702px;
  transform: rotate(-30.367deg);
  border-radius: 1000px;
  transform: translate(-40%, -40%);
  background: linear-gradient(197deg,
      #13a9c7 0%,
      rgba(255, 255, 255, 0.2) 100%);
  mix-blend-mode: darken;
}

.chatContainerBg4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 827.829px;
  height: 845.702px;
  background: #fff;
  opacity: 0.55;
}

.chatContainer {
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  contain: content;
  display: flex;
  flex-direction: column;
  margin: 36px;
  height: calc(100% - 72px);
}

.chatHeaderContainer {
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
  width: 100%;
  display: flex;
}

.selfChatHeader {
  /* width: 25% !important; */
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

.openedChatHeader {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
}

.openedChatHeader .selfChatHeader,
.selfChatHeader .allChatListDisplayContainer label,
.selfChatHeaderActionsContainer svg {
  cursor: pointer;
}

.chatConversationContainerBeforeActive {
  background-color: #d8f0f2;
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
}

.BeforeActiveFontWelcome {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
  color: #1296b0;
}

.BeforeActiveFontintro {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #555555;
  margin-bottom: 50px;
}

.chatContactListContainer .chatHeaderContainer .selfChatHeader .selfChatHeaderActionsContainer {
  display: flex;
  align-items: center;
}

.chatContactListContainer .chatHeaderContainer .selfChatHeader .selfChatHeaderActionsContainer div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatContactListContainer .chatHeaderContainer .selfChatHeader .selfChatHeaderActionsContainer .newChatAndGroupContainer div:nth-child(1) {
  height: 40px;
  width: 40px;
  padding: 10px;
}

.chatContactListContainer .chatHeaderContainer .selfChatHeader .selfChatHeaderActionsContainer .newChatAndGroupContainer div:nth-child(2) {
  height: 40px;
  width: 40px;
  padding: 6px;
  margin-bottom: 3px;
}

.chatContactListContainer .chatHeaderContainer .selfChatHeader .selfChatHeaderActionsContainer svg {
  height: 100%;
  width: 100%;
}

.chatContactListContainer .chatHeaderContainer .selfChatHeader .selfChatHeaderActionsContainer .newChatAndGroupContainer div:hover {
  background: var(--primary-hover);
  border-radius: 100px;
}

.selfChatHeaderActionsContainer div {
  display: flex;
  align-items: center;
}

.selfChatHeaderActionsContainer {
  margin-left: auto;
  display: flex;
  /* align-items: flex-start; */
  /* gap: 22px; */
  margin-right: 12px;
  /* margin-top: 6px; */
}

.HamburgerOptionWrapper {
  border: 1px solid #1296b038;
  background-color: red;
  position: relative;
  right: 20px;
  top: 100px;
  background-position: top;
}

.openedChatHeader>.verticalLine {
  background-color: #f1f2f2;
}

.chatMainContainer {
  flex-grow: 1;
  width: 100%;
  display: flex;
  height: calc(100% - 64px);
}

.chatListHeader {
  display: flex;
  cursor: pointer;
}

.settingsHeader {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  margin: 12px;
  padding: 1px;
  border-radius: 4px;
  background: #d8f0f2;
}

.settingsBody {
  display: flex;
  align-items: center;

  margin: 24px 12px;

  justify-content: start;
  gap: 13px;
  font-size: 14px;
  font-weight: 500;
}

.chatListHeader .allChatListDisplayContainer label {
  cursor: pointer;
}

.chatContactListContainer {
  width: 27%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #d8f0f2;
  min-width: 345px;
}

.anniversaryContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatConversationContainer {
  flex-grow: 1;
  height: 100%;
  width: 52%;
  display: flex;
  background-color: white;
  border-radius: 0px 0px 8px 0px;
  flex-direction: column;
}

.chatConversationContainer .openedChatHeader,
.chatContactListContainer .selfChatHeader {
  width: 100%;
}

.mySelfChatComponent {
  height: 100%;
}

.mySelfChatComponent .chatConversationContainer .chatInputContainer {
  background-color: #fff;
  border-radius: 0px 0px 8px 0px;
  min-height: 54px;
  display: flex;
  padding: 10px 18px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  flex-direction: row;
}

.postMainName {
  font-weight: 500;
  font-size: 14px !important;
  color: var(--primary);
}

.allChatListDisplayContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
}

.allChatListDisplayContainer>label {
  font-size: 13px;
  font-weight: 0 !important;
}

.chatListSearchContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatListSearch {
  box-sizing: border-box;
  background: #eff9fa;
  border: 0.5px solid #d8f0f2;
  border-radius: 4.65352px;
  margin: 12px 0;
  width: 93%;
  padding: 6px 12px;
  outline: none;
}

.chatListSearchContainer>svg {
  position: absolute;
  right: 6%;
}

.mySelfChatContainer {
  /* flex-grow: 1; */
  width: 100%;
  display: flex;
  padding: 10px 0;
  justify-content: center;
  border-bottom: 1px solid #d8f0f2;
  border-top: 1px solid #f1f2f2;
}

.mySelfChatContainer .mySelfChatBox {
  display: flex;
  width: 93%;
  border-radius: 4.65352px;
}

.mySelfChatBox .activeImage {
  margin-left: 12px;
}

.customProfilePic {
  color: var(--primary);
  font-size: 16px;
  font-weight: 600;
  background-color: #e2fafc;
}

.chatListContainer {
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 561px;
}

.chatListContainer::-webkit-scrollbar-button:single-button {
  display: block;
  background-size: 7px;
}

.chatListContainer::-webkit-scrollbar-button:single-button:increment {
  height: 12px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(231, 231, 231)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.chatListContainer::-webkit-scrollbar-button:single-button:decrement {
  height: 12px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(231, 231, 231)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.chatListContainer::-webkit-scrollbar {
  width: 13px;
}

.chatListContainer::-webkit-scrollbar-track {
  background: #fff;
}

.chatListContainer::-webkit-scrollbar-thumb {
  border: 5px solid #fff;
  background-color: #e7e7e7;
  border-radius: 15px;
}

.conversationListItem .chatListContentContainer:hover {
  background-color: #f2f2f2;
}

.conversationListItem .active:hover {
  background-color: #eff9fa !important;
}

.conversationListItem .active .chatUnreadCount:hover {
  background-color: #1296b0 !important;
}

/* .conversationListItem .active .chatListRightContent :hover {
  background-color: #EFF9FA;
}

.conversationListItem .active .chatListLeftcontent :hover {
  background-color: #EFF9FA;
} */

.chatListContentContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0rem;
}

.chatListContentContainer .chatListLeftcontent {
  display: flex;
}

.chatListContentContainer .chatListRightContent {
  display: flex;
}

.chatListContentContainer .chatListRightContent .anniversaryContainer {
  margin-right: 25px;
}

.chatListContentContainerActive.active {
  color: var(--primary);
}

.chatListContentContainerActive.active {
  background-color: rgba(216, 240, 242, 0.4);
}

.chatListContentContainer>img {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  /* margin: 1px 2px; */
}

.chatListName {
  font-size: 16px !important;
  color: #555;
  font-weight: 500;
}

.chatListRole {
  color: #555;
  font-size: 12px !important;
  font-weight: 500;
}

.chatListlastMsg {
  color: #a1a1a1;
  font-family: Poppins;
  font-size: 13px !important;
}

.TimeUnreadCountContainer {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 12px;
  font-size: 11px;
  font-weight: 400;
  color: var(--primary-text);
}

.TimeUnreadCountContainer .chatTime {
  color: #bfbfbf;
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.318px;
  /* 166.528% */
}

.chatUnreadCount {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;

  color: white;
  background-color: var(--primary) !important;
  text-align: center;
  border-radius: 10px;
  padding: 0px;
  width: 50%;
  align-self: end;
}

.forwardMsgContainer {
  background-color: #dfecef !important;
  width: 91%;
  display: flex;
  padding: 10px 12px;
  padding-top: 0;
  border-radius: 8px;
  background: #f8f8f8;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: 32px;
}

.forwardMsgContainer .forwardMsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 4.2px solid #1296b0;
  padding-top: 5px;
}

.forwardMsgContainer .forwardMsg .forwardMsgIcon {
  display: flex;
}

.forwardMsgContainer .forwardMsg .forwardMsgProfileIcon {
  width: 23px;
  height: 23px;
  border-radius: 100px;
}

.forwardMsgContainer .forwardMsgText {
  text-align: start;
  width: 100%;
  font-size: 12px;
  padding-top: 8px;
}

.forwardMsgContainer .forwardMsgProfileName {
  font-size: 12px;
  font-weight: bold;
  color: #1296b0;
  padding-left: 5px;
}

.chatInputOptions {
  width: 100%;
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
}

.chatInputContainer {
  background-color: #fff;
  border-radius: 0px 0px 8px 0px;
  min-height: 54px;
  display: flex;
  padding: 10px 18px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  flex-direction: column;
}

.InputEmojiIcon {
  position: relative;
}

.InputEmojiIcon>svg {
  padding-top: 0.2rem;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.InputEmojiIcon .EmojiPickerReact {
  position: absolute !important;
  bottom: 35px;
  left: 12px;
}

.chatInputContainer>svg:hover {
  cursor: pointer;
}

.chatInputWrapper {
  width: 92%;
  display: flex;
  padding: 10px;
  border-radius: 10px;
  background: #f8f8f8;
  justify-content: center;
  align-items: center;
  margin: 0px 8px;
}

.chatInputWrapper .crossText {
  cursor: pointer;
  margin-right: 10px;
}

.chatInputWrapper>svg {
  width: 18px;
  height: 19px;
}

.chatInput {
  border: none;
  width: 100%;
  background: #f8f8f8;
  padding-left: 6px;
}

.chatInput::placeholder {
  color: #9e9e9e;
}

.chatInput:focus {
  outline: none;
}

.sendCrossInputIcon {
  width: 20px;
  height: 20px;
}

.sendCrossInputIcon>svg {
  width: 100%;
  height: 100%;
}

.sendCrossInputIcon .allAttachmentOptions {
  position: relative;
  bottom: 90px;
  left: -7px;
  /* padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px; */
}

.sendCrossInputIcon .allAttachmentOptions .attachOption {
  background-color: #fff;
}

.attachActionsContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 10px;
  left: 36px;
  bottom: 60px;
  height: 0;
  overflow: hidden;
  transition: 0.2s;
  width: 30px;
}

.attachActionsActive {
  height: 110px;
  transition: 0.2s;
}

.attachActionFrame {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: #da5db6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.attachActionFrame>svg {
  width: 65%;
  height: 65%;
}

.chatConversationMsgsContainer {
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column-reverse;
  overflow-y: auto;
  gap: 6px;
  align-items: center;
  padding-bottom: 12px;
}

.chatConversationMsgsContainer .dateMsg {
  position: absolute;
  top: 100px;
  color: grey;
  background-color: #fff;
  padding: 5px;
  font-size: 13px;
  border-radius: 5px;
}

.chatConversationMsgsContainer .selectMsgChkbox {}

.chatConversationMsgsContainer .imgTimeContainer {
  display: flex;
  flex-direction: column;
}

.chatConversationMsgsContainer .imgTimeContainer img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.chatConversationMsgsContainer .chatMsgSelf {
  display: flex;
  /* align-items: center; */
}

.chatConversationMsgsContainer .chatMsg .selectMsgChkbox {
  margin: 10px 15px 0px 0px;
}

.chatConversationMsgsContainer .chatMsgSelf .selectMsgChkbox {
  margin: 10px 5px 0px 5px;
}

.chatMsg {
  width: 97%;
  display: flex;
  /* align-items: center; */
  padding: 14px 0;
  position: relative;
}

.chatMsg .chatMsgTextContainer .msgActivityContainer>svg path {
  stroke: var(--primary);
}

.chatMsgSelf .chatMsgTextContainer .msgActivityContainer>svg path {
  stroke: var(--white);
}

.chatMsgSelf {
  display: flex;
  flex-direction: row-reverse;
}

.chatMsg>img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.chatMsgTextContainer {
  background-color: var(--white);
  box-sizing: border-box;
  border: 0.5px solid #d8f0f2;
  border-radius: 0px 6px 6px 6px;
  color: var(--black);
  margin: 0 12px;
  padding: 6px 8px 6px 8px;
  position: relative;
  height: fit-content;
}

.chatMsgTextContainer .msgActivityContainer>svg {
  position: absolute;
  top: 4px;
  right: 3px;
  width: 10.137px;
  height: 5.068px;
  display: none;
  cursor: pointer;
}

.chatMsgTextContainer:hover .msgActivityContainer>svg {
  display: block;
}

.chatMsgTextContainer>label {
  font-size: 13px;
  display: block;
}

.chatMsgSelf .chatMsgTextContainer>label {
  text-align: end;
}

.chatMsgSelf>.chatMsgTextContainer {
  background-color: var(--primary);
  border-radius: 6px 0px 6px 6px;
  color: var(--white);
}

.chatMsgTextContainer .chatRepliedContainer {
  background-color: #dfecef;
  border-radius: 0px 5px 5px 5px;
  padding: 5px 8px;
  color: var(--primary);
}

.chatMsgTextContainer .chatRepliedContainer .repliedName {
  font-size: 10.2px;
}

.chatMsgTextContainer .chatRepliedContainer .repliedMsg {
  font-size: 13px;
}

.chatMsgSelf .chatMsgTextContainer .chatRepliedContainer {
  text-align: end;
  border-radius: 5px 0px 5px 5px;
}

.chatMsgDateTime {
  font-weight: 400;
  font-size: 10px;
}

.spacePad {
  padding: 12px;
}

.greenDot {
  background-color: #00ab00;
  border-radius: 100%;
  position: absolute;
  height: 10px;
  width: 10px;
  bottom: 0;
  right: 0;
}

.statusTime {
  position: absolute;
  font-size: 9px;
  right: 14px;
  font-size: 9px;
}

.status-item {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid var(--primary);
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.status-item.seen {
  border-color: grey;
}

.status-item.unseen {
  border-image-slice: 1;
}

.status-container {
  display: flex;
  gap: 5px;
}



.status-segment {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  /* Adjust as needed */
  height: 40px;
  /* Adjust as needed */
  border-right: 2px solid transparent;
  /* Initial border color */
  border-radius: 0;
}


.addIm {
  position: absolute;
  height: 10px;
  width: 10px;
  bottom: 5px;
  right: 9px;
}

.yellowDot {
  background-color: #f7941d;
  border-radius: 100%;
  position: absolute;
  height: 10px;
  width: 10px;
  bottom: 0;
  right: 0;
}

.redDot {
  background-color: #f71d1d;
  border-radius: 100%;
  position: absolute;
  height: 10px;
  width: 10px;
  bottom: 0;
  right: 0;
}

.groupDot {
  position: absolute;
  height: 10px;
  width: 10px;
  bottom: 8px;
  right: 10px;
}

.offlineDot {
  background: url();
  background-color: grey;
  border-radius: 100%;
  position: absolute;
  height: 10px;
  width: 10px;
  bottom: 0;
  right: 0;
}

.activeImage {
  height: 42px;
  width: 42px;
  border-radius: 100%;
  margin: 12px;
  position: relative;
}

.activeImageSetting {
  height: 73px;
  width: 73px;
  border-radius: 100%;
  margin: 12px;
  position: relative;
}

.activeImage>img,
.activeImage .activeImageContainer {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  background-color: #e9f3f4;
}

.activeImage .activeImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selfChatHeader .activeImage:not(.openedChatHeader .activeImage),
.chatListContainer .activeImage {
  margin-left: 24px;
}

.selectMsgChkbox {
  margin-right: 10px;
}

.selectInput[type="checkbox"]:before {
  position: relative;
  display: block;
  content: "";
  background: #fff;
}

.selectInput[type="checkbox"]:after {
  position: relative;
  display: block;
  left: 2px;
  top: -11px;
  width: 7px;
  height: 7px;
  content: "";
  border: 1px solid #1296b0;
  border-radius: 3px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}

.selectInput[type="checkbox"]:checked:after {
  background-image: url("../../Assets/RightCheck.svg"),
    linear-gradient(135deg, #1296b0 0%, #1296b0 100%);
  border: none;
}

.selectInput[type="checkbox"]:not(:disabled):checked:hover:after {
  background-image: url("../../Assets/RightCheck.svg") linear-gradient(135deg, #1296b0 0%, #1296b0 100%);
  border: none;
}

.selectInput[type="checkbox"]:not(:disabled):hover:after {
  background-color: #c9c9c9;
  border: none;
}

.selectInput.large[type="checkbox"]:before {
  width: 20px;
  height: 20px;
}

.selectInput.large[type="checkbox"]:after {
  top: -20px;
  width: 16px;
  height: 16px;
}

.selectMsgForwardDeleteContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectMsgForwardDeleteContainer .selectMsgCrossCountIcon {
  display: flex;
}

.selectMsgForwardDeleteContainer .selectMsgDeleteForwardIcon {
  display: flex;
  align-items: center;
}

.selectMsgForwardDeleteContainer .selectMsgDeleteForwardIcon div {
  padding: 0 5px;
}

.selectMsgForwardDeleteContainer .selectMsgDeleteForwardIcon div,
.selectMsgForwardDeleteContainer .selectMsgCrossCountIcon div {
  cursor: pointer;
}

.deleteSelectedMsgContainer {
  background-color: #fff !important;
  padding: 30px;
  width: 500px;
  border-radius: 10px;
}

.deleteSelectedMsgContainer h4 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 70px;
  color: #555;
}

.deleteSelectedMsgBtnContainer {
  float: right;
}

.deleteSelectedMsgBtnContainer button:nth-child(1) {
  border: none;
  padding: 15px 20px;
  margin-right: 10px;
  color: #f71d1d;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 8.495px;
  border-radius: 3px;
  background: var(--white, #fff);
  box-shadow: 0px 4px 8px 0px rgba(207, 207, 207, 0.4),
    0px 1px 10px 0px rgba(0, 0, 0, 0.05) inset;
}

.deleteSelectedMsgBtnContainer button:nth-child(2) {
  margin-right: 10px;
  border: none;
  padding: 15px 25px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 9px;
  border-radius: 3px;
  background: var(--people-s-ol-primery, #1296b0);
  box-shadow: 0px 4px 8px 0px rgba(18, 150, 176, 0.2),
    0px 4px 10px 0px rgba(0, 0, 0, 0.15) inset;
}

.deleteSelectedMsgBtnContainer button:nth-child(3) {
  border: none;
  padding: 15px 25px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 9px;
  border-radius: 3px;
  background: var(--people-s-ol-primery, #1296b0);
  box-shadow: 0px 4px 8px 0px rgba(18, 150, 176, 0.2),
    0px 4px 10px 0px rgba(0, 0, 0, 0.15) inset;
}

.newChatPopup1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}

.newChatPopup2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  border-radius: 6px;
  background: #faf9f8;
  min-width: 400px;
  max-width: 400px;
  min-height: 70%;
  max-height: 70%;
}

.newChatPopupBodyContainer {
  height: calc(100% - 49px);
  overflow: auto;
  overflow-x: hidden;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
}

.input-box {
  width: 100%;
  padding-left: 12px;
  /* position: absolute; */
  display: flex;
  align-items: center;
  height: 60px;
  padding: 8px;
}

.input-box input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 20px;
  margin: 0 8px;
  outline: none;
}

.input-icons {
  display: flex;
  align-items: center;
}

.input-icons .icon {
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.DropdownArrowChatDesign {
  position: absolute;
  right: 7px;
  top: 7px;
  display: none;
}

.message-content:hover .DropdownArrowChatDesign {
  display: block;
}

.chatActionsPopup {
  width: 140px;
  background-color: white;
  box-shadow: 0px 4px 10px 0px #0000001a;
  border-radius: 6px;
}

.chatActionsPopup div:hover {
  background-color: #f2f2f2;
}

.chatActionsPopup div {
  padding: 10px;
  cursor: pointer;
}

.message-date-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

.message-date-header>div {
  background: #ffffff;
  box-shadow: 0px 1px 6px 0px #0000000d;
  padding: 3px 7px;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
}

.replyContainer {
  height: 70px;
  border-radius: 8px;
  background-color: #dfecef;
  margin-left: 64px;
  margin-right: 74px;
  margin-top: 10px;
  border-radius: 8px;
}

.ReplyNameChat {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #1296b0;
  margin-left: 7px;
  margin-top: 5px;
}

.ReplyContentChat {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #555555;
  margin-left: 7px;
  margin-top: 5px;
}

.translucent-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.25);
  /* Translucent white */
  pointer-events: none;
  /* Allows clicks to pass through */
}

.targetDiv::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.document-preview-container {
  width: 100%;
  height: 100%;
  background-color: #d8f0f2;
  border-radius: 0px 0px 8px 0px;
}

.header {
  height: 50px;
  background-color: #1296b0;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
  justify-content: center;
}

.file-name {
  width: 97%;
  text-align: center;
}

.selected-file-icon {
  position: absolute;
  right: 13px;
  top: 13px;
}

.preview {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  flex: 1;
}

.input-textarea-chat {
  width: 637.46px;
  min-height: 20px;
  max-height: 77px;
  border-radius: 6px 6px 6px 6px;
  background-color: #fafafa;
  border: 0.8px solid #d8f0f2;
  resize: none;
  padding: 6px;
  overflow-y: auto;
}

.editable-div:empty:before {
  content: attr(data-placeholder);
  color: grey;
  pointer-events: none;
  display: block;
  /* For IE */
}

.file-preview {
  margin-bottom: 5px;
}

.input-textarea {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.send-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}

.targetDiv.translucent-overlay::after {
  opacity: 1;
}

.forwardInputChat {
  height: 60px;
  width: 100%;
  padding: 10px 10px 10px 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.forwardInputChatDiv1 {
  display: flex;
  align-items: center;
}

.forwardInputChatDiv2 {
  display: flex;
  align-items: center;
}

.forwardPopup-chat {
  justify-content: flex-start;
  background-color: #ffffff;
  padding: 0px;
}

.chatForwardListHover:hover {
  /* background-color: rgba(0, 0, 0, 0.05) */
  background-color: #e9f3f4;
}

.overflowStop {
  overflow: hidden;
}

.createHeadForward {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #1296b0;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: 6px;
}

.viewAllButton:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.custom-ui-chat-Delete {
  padding: 20px 20px 5px 20px;
  background-color: white;
  width: 100%;
  height: 169px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
}

.deleteMessageFont {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 500;
  text-align: left;
  color: #555555;
}

.cancelButton {
  color: #f71d1d;
  font-family: Poppins;
  font-weight: 500;
  text-align: center;
  position: static;
  box-shadow: 0px 4px 8px 0px #cfcfcf66;
  background: #ffffff;
  box-shadow: 0px 1px 10px 0px #0000000d inset;
}

/* 
.chatDeleteButton:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);

} */
.cover-chat-document {
  height: calc(100% - 107px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.activeSerlectedFile {
  border: 2px solid #1296b0 !important;
}

.eachImageHoverCover:hover .eachImageHover {
  height: 70% !important;
}

.eachImageHoverCover {
  height: 63px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eachImageHoverDelete {
  height: 0%;
  width: 84%;
  text-align: center;
  background-color: #fdd2d2;
  color: #f71d1d;
  font-size: 10px;
  margin-top: 6px;
  border-radius: 3px;
}

.eachImageHoverDelete>p {
  display: none;
}

.eachImageHoverCover:hover .eachImageHoverDelete {
  height: 30% !important;
  padding: 2px;
}

.eachImageHoverCover:hover .eachImageHoverDelete>p {
  display: block;
}

/* .custom-scrollbar-chat {
  scrollbar-width: thin;
} */

.document-preview-container-uploading {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.3);
  top: 0px;
  left: 0px;
}

.mediaCoverChat {
  width: 350px;
  height: 240px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  border-radius: 6px;
}

.mediaCoverChatBundled {
  width: 150px;
  height: 109px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px 3px;
  border-radius: 6px;
}

.mediaCoverChatBundledAbsolute {
  min-width: 20px;
  left: -20px;
  top: 0;
  height: 100%;
  background-color: #1296b0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mediaCoverChatBundledAbsoluteTop {
  background-color: #EFF9FA;
  height: calc(50% - 21px);
  border-radius: 0px 0px 12px 0px;
}

.mediaCoverChatBundledAbsoluteBottom {
  background-color: #EFF9FA;
  height: calc(50% - 21px);
  border-radius: 0px 12px 0px 0px;
}

.mediaCoverChatBundledAbsoluteCenter {
  height: 42px;
  border-radius: 21px 0px 0px 21px;
  position: absolute;
  top: calc(50% - 21px);
  right: 0px;
  min-width: 44px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mediaCoverChatBundledAbsoluteCenterRecieve {
  height: 42px;
  border-radius: 0px 21px 21px 0px;
  position: absolute;
  top: calc(50% - 21px);
  left: 0px;
  min-width: 44px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mediaCoverChatBundledAbsoluteBottomRecieve {
  background-color: #EFF9FA;
  height: calc(50% - 21px);
  border-radius: 12px 0px 0px 0px;
}

.mediaCoverChatBundledAbsoluteTopRecieve {
  background-color: #EFF9FA;
  height: calc(50% - 21px);
  border-radius: 0px 0px 0px 12px;
}

.mediaCoverChatBundledAbsoluteRecieve {
  min-width: 20px;
  right: -20px;
  top: 0;
  height: 100%;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popupMedia {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}

.popupMediaPopup {
  width: 925px;
  height: 570px;
  margin-top: 10px;
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.mediaViewerChat {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  margin: 10px 10px;
  border-radius: 3px;
  flex-grow: 0;
  flex-shrink: 0;
}

.pdfChatViewer {
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.pdfChatViewer .react-pdf__Page {
  --scale-factor: 1;
  background-color: white;
  position: static !important;
  /* min-width: min-content; */
  /* min-height: min-content;*/
}

.pdfChatViewer .react-pdf__Page__canvas {
  display: flex !important;
  user-select: none !important;
  width: 286px !important;
  height: 152px !important;
  object-fit: cover !important;
  object-position: top !important;
  position: absolute !important;
  border-radius: 6px !important;
}

.pdfChatViewer .textLayer {
  position: absolute;
  text-align: initial;
  inset: 0;
  overflow: hidden;
  line-height: 1;
  text-size-adjust: none;
  forced-color-adjust: none;
  transform-origin: 0 0;
  z-index: 2;
  width: 100px !important;
  height: 100px !important;
}

.pdfChatViewer .react-pdf__Page__textContent {
  position: absolute;
  text-align: initial;
  inset: 0;
  overflow: hidden;
  line-height: 1;
  text-size-adjust: none;
  forced-color-adjust: none;
  transform-origin: 0 0;
  z-index: 2;
  width: 100px !important;
  height: 100px !important;
}

.pdfChatViewer .react-pdf__Page__annotations {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 3;
  width: 100px !important;
  height: 100px !important;
}

.pdfChatViewer .annotationLayer {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 3;
  width: 100px !important;
  height: 100px !important;
}

.unselectable {
  -webkit-user-select: none;
  /* Safari and Chrome */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Standard syntax */
}
.table {
  white-space: nowrap;
  border-collapse: separate;
  border-spacing: 0 6px;
  position: relative;
  table-layout: fixed;
  flex-grow: 1;
  display: block;
  overflow: auto;
}
.thead {
  font-weight: 500;
  background-color: var(--table-head-back);
  position: sticky;
  top: 6px;
  z-index: 5;
}
.table > thead > tr > td {
  padding: 12px;
}
.table > tbody > tr > td {
  padding: 12px;
  background-color: var(--table-back);
}
.optionsCell {
  left: 0;
  position: sticky;
  z-index: 4;
  background-color: var(--table-head-back);
  padding: 12px 0px 12px 0px !important;
}
.optionsCell > button > svg {
  rotate: 90deg;
}
.optionsRow {
  left: 0;
  position: sticky;
  background-color: var(--table-back);
  z-index: 1;
  padding: 12px 0px 12px 12px !important;
}
.actionsCell {
  left: 70px;
  position: sticky;
  z-index: 4;
  background-color: var(--table-head-back);
  box-shadow: 3px 0px 0px -1px var(--primary);
}
.actionsCellCheckInactive {
  left: 29px;
  position: sticky;
  z-index: 4;
  background-color: var(--table-head-back);
  box-shadow: 3px 0px 0px -1px var(--primary);
}
.actionsRow {
  left: 70px;
  position: sticky;
  z-index: 1;
  background-color: var(--table-back);
  box-shadow: 2px 0px 0px 0px var(--primary-hover);
  padding: 12px 6px !important;
}
.actionsRowCheckInactive {
  left: 29px;
  position: sticky;
  z-index: 1;
  background-color: var(--table-back);
  box-shadow: 2px 0px 0px 0px var(--primary-hover);
  padding: 12px 6px !important;
}
.checkCell {
  left: 29px;
  position: sticky;
  z-index: 4;
  background-color: var(--table-head-back);
  padding: 12px 0px 12px 6px !important;
}
.checkRow {
  left: 29px;
  position: sticky;
  background-color: var(--table-back);
  z-index: 1;
  padding: 12px 0px 12px 6px !important;
}
.timesheet > .thead > tr > td:first-child {
  position: sticky;
  left: 0;
  background-color: var(--table-head-back);
  word-wrap: break-word !important;
}
.timesheet > tbody > tr > td:first-child {
  position: sticky;
  left: 0;
  background-color: var(--table-back);
  word-wrap: break-word !important;
  white-space: pre-wrap;
}
.tableScrolled > .thead > tr > td:first-child {
  box-shadow: 5px 0 5px -5px #333;
}
.tableScrolled > tbody > tr > td:first-child {
  box-shadow: 5px 0 5px -5px #333;
}
.timesheet > .thead > tr > td:last-child {
  position: sticky;
  right: 0;
  background-color: var(--table-head-back);
}
.timesheet > tbody > tr > td:last-child {
  position: sticky;
  right: 0;
  background-color: var(--table-back);
}
.firstCellHeader:nth-child(1) {
  color: var(--primary);
}
.firstCellHeader:nth-child(2) {
  color: var(--secondary);
}
.firstCellHeader:nth-child(3) {
  color: var(--primary-text);
}
.firstCellHeader:nth-child(4) {
  font-weight: 400;
  font-size: 14px;
}
.dateOfDay {
  font-weight: 400;
  font-size: 14px;
  align-self: center;
}
.footerRow {
  position: sticky;
  bottom: -1px;
  font-weight: 500;
}
.footerCell {
  background-color: var(--table-head-back) !important;
}
.timesheetHourBar {
  width: 100% !important;
  height: 9px !important;
}
.timesheetHourBar > .skillBarLine {
  height: 9px !important;
}
.tableHeaderCell {
  display: flex;
  gap: 12px;
  align-items: center;
}
.headingSort {
  width: 14px;
  height: 14px;
}
.headingSortActive > .sortTypeActive {
  fill: var(--primary);
}
.tableContainer {
  display: flex;
  overflow: auto;
  flex-grow: 1;
  height: calc(100% - 105px);
  position: relative;
  z-index: 0;
}
.headingFilterContainer {
  position: absolute;
  z-index: 9;
  background-color: transparent;
  padding: 0;
  left: 0px;
  top: 7px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  box-shadow: 5px 6px 15px rgb(0 0 0 / 5%);
  border-radius: 6px;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  width: fit-content;
  border: 1px solid transparent;
}
.headingFilterContainerActive {
  height: calc(100% - 7px);
  border: 1px solid #1296b038;
  background-color: white;
}
.selectedRow {
  color: var(--primary);
}
.headingFilterHeader {
  display: flex;
  padding: 12px;
  border-bottom: 1px solid var(--grey);
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  color: var(--primary);
}
.headingFilterBodyContainer {
  overflow: auto;
  padding-right: 40px;
}
.onlyTable {
  height: calc(100% - 60px) !important;
}
.statusCell {
  color: white;
  padding: 3px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 6px 1px;
  width: fit-content;
}
.moreActionContainer {
  position: absolute;
  background-color: white;
  left: 29px;
  top: 5px;
  border: 1px solid #1296b038;
  box-shadow: 5px 6px 15px rgb(0 0 0 / 5%);
  border-radius: 6px;
  contain: content;
  z-index: 1;
}
.moreActionTile {
  padding: 12px;
  display: flex;
  gap: 6px;
  align-items: center;
}
.moreActionTile:hover {
  background-color: var(--primary-hover);
  color: var(--primary);
}
.moreActionActive {
  z-index: 2;
}
.actionCellContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.actionsRow > button {
  padding: 4px;
}
.actionCellContainer > button {
  padding: 4px;
}

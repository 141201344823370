.mainHomeDashboardContainer {
  overflow: scroll;
  width: 100%;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  position: relative;
}

.mainHomeDashboardContainer::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.homeDashboardContainer {
  display: flex;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  background-color: var(--white);
  flex-shrink: 0;
  contain: content;
}

.homeSecondaryContainer {
  margin: 24px 24px 0 24px;
  display: flex;
  flex-shrink: 0;
  gap: 24px;
}

.homeSecondaryDash {
  justify-content: space-around;
  background-color: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  padding: 12px;
  gap: 12px;
  align-items: center;
}

.homeDashboardMargin {
  margin: 24px 24px 0 24px;
  padding: 0 12px;
}

.profileImgHolder {
  height: 100%;
  width: 407px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: -32px;
}

.profileImgHolders {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.profileImgHolderbackground {
  position: absolute;
  height: 100%;
}

.avatarContainer {
  width: 10rem;
  height: 10rem;
  border-radius: 100%;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
  contain: content;
  margin-right: 18px;
}

.avatarContainer:hover {
  background-color: lightgray;
}

.avatarBadge {
  position: absolute;
  background-color: var(--secondary);
  margin-left: 8rem;
  margin-top: 6rem;
  z-index: 2;
  padding: 0px 10px;
  border-radius: 20px;
  color: var(--white);
  font-size: 12px;
}

.infoIcon {
  position: absolute;
  width: 1rem;
  height: 1rem;
  margin-top: -5px;
}

.imageInsideProgressBar {
  z-index: -1;
  width: 100%;
  height: 100%;
}

.homeGreetingContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-left: -27px;
  flex-grow: 1;
}

.morningGreetingContainer {
  display: flex;
  align-items: center;
  color: var(--primary);
  gap: 5px;
  position: relative;
}

.horizontalLinePrimary {
  width: 40px;
  margin-top: 5px;
}

.starContainer {
  background: #ffffff;
  box-shadow: 0px 2.40743px 12.0372px rgb(0 0 0 / 6%);
  border-radius: 10.8335px;
  margin-top: 1.5rem;
  display: flex;
  color: var(--primary);
  align-items: flex-start;
  width: fit-content;
}

.starContainer>label {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
}

.homeDashboardFrameContainer {
  display: flex;
  width: 312px;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 12px 0;
  flex-shrink: 0;
}

.prrevdash {
  padding: 0 10px;
  width: fit-content;
  justify-content: space-between;
  display: flex;
  color: var(--white);
  align-items: center;
  font-size: 12px;
  align-items: flex-start;
}

.prreviewBox {
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  gap: 15px;
}

.ltblue {
  background: #eff9fa !important;
  color: black !important;
  border-radius: 9.71821px;
}

.ltpink {
  background: #ffeef2 !important;
  color: black !important;
  border-radius: 9.71821px;
  justify-content: space-between;
}

.ltpurple {
  background: #f0f0ff !important;
  color: black !important;
  border-radius: 9.71821px;
}

.ltpeach {
  background: #fff2f0 !important;
  color: black !important;
  border-radius: 9.71821px;
}

.cardRatingEmp {
  width: 100%;
  flex-direction: column;
  display: flex;
  padding: 8px;
  align-items: initial;
  gap: 11px;
  height: 287.29px;
  margin: 0 24px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 1.89445px 10px;
  border-radius: 6px;
  font-size: 19px;
  height: fit-content;
}

.starholderpms {
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  align-items: center;
  margin: 0px 6px;
  background: #f9f9f9;
  padding: 6px;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 1.89445px 10px;
}

.starholderpmself {
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  align-items: center;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 12px;
  gap: 20px;
}

.headtexts {
  font-size: 13px;
  font-weight: 500;
}

.heademp {
  font-size: 11px;
}

.valheademp {
  font-size: 15px;
  font-weight: 500;
}

.prpstatus {
  box-shadow: 0px 1.89445px 10px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  display: flex;
  gap: 12px;
  padding: 24px;
  justify-content: space-around;
  margin: 0 24px;
}

.homeDashboardFrame {
  background: linear-gradient(0deg, #fb7158, #fb7158), #f04e30;
  border-radius: 6px;
  height: 150px;
  width: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--white);
  font-size: 12px;
  align-items: flex-start;
  font-weight: 400;
  justify-content: space-between;
}

.homeDashboardFrameHorizontal {
  background: linear-gradient(0deg, #fb7158, #fb7158), #f04e30;
  border-radius: 8.45708px;
  height: calc(50% - 6px);
  width: 100%;
  position: relative;
  display: flex;
  color: var(--white);
  font-size: 12px;
  align-items: flex-start;
  font-weight: 400;
  justify-content: space-between;
}

.homeDashboardCountHolder {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 12px;
}

.frameCountHolder>label {
  font-size: 12px;
}

.coral {
  background: #5bc4bf;
}

.purple {
  background: linear-gradient(0deg, #8a88ed, #8a88ed), #8a88ed;
}

.pink {
  background: #da5db6;
}

.secondaryOne {
  background: linear-gradient(133.34deg, #9bd1dc 0%, #6ab1bf 80.16%);
}

.secondaryTwo {
  background: linear-gradient(133.34deg, #d1c0cf 0%, #b69fb3 80.16%);
}

.secondaryThree {
  background: linear-gradient(133.34deg, #a9ddcc 0%, #82c1ad 80.16%);
}

.secondaryFour {
  background: linear-gradient(133.34deg, #cdcdea 0%, #a0a0cb 80.16%);
}

.homeDashboardFrame>label {
  font-size: 14px;
}

.homeDashboardFrame>img {
  right: 0;
  top: 0;
  position: absolute;
  mix-blend-mode: luminosity;
}

.homeDashboardFrame>.horizontalLine {
  left: 12px;
  top: 15px;
  width: 30px;
  position: absolute;
}

.homeDashboardFrame>label {
  width: 40%;
  margin-left: 12px;
  margin-top: 25px;
}

.homeDashboardFrameHorizontal>label {
  font-size: 14px;
}

.homeDashboardFrameHorizontal>img {
  right: 0;
  top: 0;
  position: absolute;
  mix-blend-mode: luminosity;
}

.homeDashboardFrameHorizontal>.horizontalLine {
  left: 12px;
  top: 15px;
  width: 30px;
  position: absolute;
}

.homeDashboardFrameHorizontal>label {
  width: 40%;
  margin-left: 12px;
  margin-top: 25px;
}

.frameCountHolder {
  display: flex;
  flex-direction: column;
  width: 35%;
  justify-content: center;
  align-items: flex-start;
}

.frameCountHolders {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.secondFrameCountHolder {
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  align-items: flex-start;
}

.homeDashFrameCount {
  font-size: 25px !important;
  font-weight: 500;
}

.homeSecondDashFrameCount {
  font-size: 14px !important;
  font-weight: 500;
}

.homeDashboardContainer>.verticalLine {
  height: 60%;
  margin-left: 2%;
  margin-right: 2%;
}

.teamActionContainer {
  width: calc(50% - 8px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  background-color: var(--primary-hover);
}

.homeDashboardHolidayFrameContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.homeHolidayFrame {
  width: 100%;
  height: calc(100% - 24px);
  background-color: aliceblue;
  border-radius: 8.45708px;
  display: flex;
  align-items: center;
  position: relative;
}

.homeHolidayFrameDataHolder {
  /* color: var(--white); */
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  /* left: 24px; */
  z-index: 1;
  /* position: absolute; */
  margin: 7px 0px 7px 0px;
  padding: 0px 10px;
}

.homeHolidayFrameDataHolderDiv {
  /* color: var(--white); */
  /* width: 50%; */
  display: flex;
  justify-content: space-between;
  align-items: start;
  /* flex-direction: column; */
  /* left: 24px; */
  z-index: 1;
  /* position: absolute; */
}

.holidaysText {
  margin-top: 10px;
  font-size: 12px;
}

.holidayName {
  font-size: 15px;
  display: flex;
  align-items: center;
  /* width: 88px; */
  flex: 1;
  line-height: 27px;
  font-weight: 500;
  color: var(--primary);
}

.holidayDate {
  font-size: 12px;
  font-weight: 500;
  /* width: 10rem; */
  display: flex;
}

.homeHolidayFrameDataHolder>.horizontalLine {
  width: 30px;
}

.homeCardsHolderContainer {
  padding: 12px 24px 12px 24px;
  gap: 0;
}

.homeDashboardCardHolder {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
  height: 100%;
}

.dashboardCard {
  width: 33.3333%;
  aspect-ratio: 1.25;
}

.cardContainer {
  margin: 12px;
  height: calc(100% - 24px);
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.cardContainer:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.headingContainer>h3 {
  color: var(--primary);
  font-size: 18px;
}

.headingContainer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px;
  gap: 6px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgb(0 0 0 / 5%);
  border-radius: 6px 6px 0px 0px;
  justify-content: space-between;
}

.dashboardCardContentHolder {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 51px);
  padding-top: 6px;
}

.cardDataContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  contain: content;
}

.editDashboardButton {
  background-color: var(--secondary-hover);
  aspect-ratio: 1;
  height: 28px;
  border-radius: 3.45801px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 98px;
  right: 24px;
}

.editDashboardButtonActive {
  background-color: #fff;
}

.editDashboardButton>svg {
  width: 80%;
  height: 80%;
  transition: 0.2s;
}

.editDashboardButtonActive>svg {
  width: 90%;
  height: 90%;
  rotate: 45deg;
  transition: 0.2s;
}

.editDashboardButtonActive>svg>.arrowHead {
  display: none;
  transition: 0.2s;
}

.editDashboardButtonActive>svg>path {
  stroke: red;
  transition: 0.2s;
}

.editDashboardButton:hover {
  cursor: pointer;
}

.editDashboardContainer {
  width: 280px;
  height: 0;
  top: 132px;
  right: 24px;
  position: fixed;
  background-color: #ffffff;
  box-shadow: -2px 0px 20px rgb(0 0 0 / 10%);
  z-index: 2;
  border-radius: 6px;
  overflow: auto;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
}

.editDashboardContainerActive {
  height: calc(100% - 132px);
  transition: 0.2s;
}

.editDashboardContainer>h4 {
  color: var(--primary);
  margin: 6px 12px;
  font-size: 14px;
}

.editDashboardContainer>.horizontalLine {
  height: 1px;
}

.editDashboardListContainer {
  overflow-y: auto;
}

.editDashboardListItemContainer {
  height: 50px;
  margin: 12px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.dashboardHolidayText {
  width: 7rem;
  display: flex;
  font-size: 16px;
  line-height: 23px;
}

.noHolidayLabel {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.teamCardTodayTextHolder {
  margin-top: 6px;
  color: var(--primary-text);
  max-width: 162px;
}

.secondaryCountHolder {
  margin-right: 18px;
  font-size: 28px;
  font-weight: 500;
  white-space: nowrap;
  margin-left: 6px;
}

.secondaryFrameContainer {
  width: 660px;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.horizontalImageContainer {
  width: 25%;
  height: 100%;
  padding: 12px;
}

.horizontalDetailContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .dashboardCard {
    width: 50%;
    aspect-ratio: 1.25;
  }

  .secondaryFrameContainer {
    width: 336px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1300px) {
  .teamActionContainer {
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1400px) {
  .homeDashboardContainer>.verticalLine {
    display: none;
  }

  .homeDashboardHolidayFrameContainer {
    display: none;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1100px) {
  .homeDashboardContainer>.verticalLine {
    display: none;
  }

  .homeDashboardHolidayFrameContainer {
    display: none;
  }

  .profileImgHolder {
    display: none;
  }

  .homeGreetingContainer {
    margin-left: 24px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 800px) {
  .homeDashboardCardHolder {
    justify-content: center;
  }

  .dashboardCard {
    width: 100%;
    aspect-ratio: 1.25;
    max-width: 600px;
  }

  .homeDashboardContainer>.verticalLine {
    display: none;
  }

  .homeDashboardHolidayFrameContainer {
    display: none;
  }

  .profileImgHolder {
    display: none;
  }

  .homeGreetingContainer {
    margin-left: 48px;
    width: 100%;
  }

  .homeDashboardMargin {
    max-width: 560px;
    align-self: center;
    flex-direction: column;
    height: fit-content;
    margin-top: 12px;
    align-items: center;
    width: 100%;
    width: calc(100% - 70px);
  }

  .homeDashboardFrameContainer {
    width: 100%;
  }

  .secondaryFrameContainer {
    width: 174px;
  }

  .teamActionContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .teamActionContainer {
    width: 100%;
  }

  .secondaryFrameContainer {
    width: 174px;
  }

  .homeDashboardCardHolder {
    justify-content: center;
  }

  .dashboardCard {
    width: 100%;
    aspect-ratio: 1.25;
  }

  .homeDashboardContainer>.verticalLine {
    display: none;
  }

  .homeDashboardHolidayFrameContainer {
    display: none;
  }

  .profileImgHolder {
    display: none;
  }

  .homeGreetingContainer {
    margin-left: 48px;
    width: 100%;
  }

  .homeDashboardMargin {
    width: 100%;
    align-self: center;
    flex-direction: column;
    height: fit-content;
    margin-top: 12px;
    align-items: center;
    width: calc(100% - 70px);
  }

  .homeDashboardFrameContainer {
    width: 100%;
  }
}

.tableContainers {
  width: 100%;
  /* Define a width for the table container */
  max-height: 70vh;
  overflow: auto;
  /* Enable scrolling */

}

.parentContainers {
  position: relative;
  height: 100vh;
  /* Set an appropriate height for the parent container */
}

.hovH:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.hovH {
  cursor: pointer;
}

.carousel .control-dots .dot {
  background: #555 !important;
  width: 10px;
  height: 10px;
  box-shadow: none !important;
}

/* Style for the active dot */
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: var(--primary) !important;
  opacity: 1;
  box-shadow: none !important;

}

.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 4px !important;
}
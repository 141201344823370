.preparePayrollSummary {
  height: 70px;
  width: calc(33.333% - 8px);
}
.preparePayrollMainContainer {
  height: calc(100% - 59px);
  padding: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  overflow: auto;
}
.preparePayrollTableContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}
.tableHeaderRow {
  position: sticky;
  top: 6px;
}
.tableHeaderRow th {
  background-color: var(--primary-hover) !important;
  white-space: nowrap;
  padding: 12px !important;
}
.taxTableRow {
  display: flex;
  margin-top: 6px;
  padding: 12px;
}
.taxTableCell:first-child {
  width: 50%;
}
.taxTableCell:nth-child(2) {
  width: 25%;
}
.taxTableCell:nth-child(3) {
  width: 25%;
}
.taxTableContainer {
  display: flex;
  flex-direction: column;
}
.summary-list {
  
  margin-top: 24px;
  background-color: var(--form-background);
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3 ,1fr);
  list-style: none;
  padding: 0;
  padding: 24px 24px;
  gap: 42px;
  flex-wrap: wrap;
  font-size: 15px;

}

.summary-list li {
  margin-bottom: 10px;
}

.summary-list li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  /* You can add more styles as per your design */
}

.summary-list li a:hover {
  color: #007bff; /* Change color on hover */
}

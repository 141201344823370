.calendarContainer {
  height: 100%;
  width: 100%;
  contain: content;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.calendarContainer::-webkit-scrollbar {
  width: 3px;
  scroll-behavior: smooth
}

.calendarContainer {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) rgba(0, 0, 0, 0.1);
}

.ant-picker-content th {
  font-weight: 600 !important;
}

.calendarContainer>.ant-picker-calendar-mini {
  height: inherit !important;
}

.attendanceCardContentContainer {
  background-color: white;
  height: 4rem;
  width: 100%;
  contain: content;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  /* border: 1px solid #bfbfbf; */
}

.cardDateDisplay {
  font-size: 11px;
  font-weight: 500;
  font-family: poppins;
  display: flex;
  justify-content: right;
  padding: 8px 5px 0px 1px;
}

.calendarContainer>.ant-picker-calendar .ant-picker-content td {
  padding: 0 0 1px 0 !important;
}

.attendanceCardPresentCell {
  display: flex;
  height: 15px;
  align-items: center;
}

.myHolderCard {
  width: 40px !important;
  height: 36px !important;
  justify-content: center;
}

.teamHolderCard {
  width: 45px !important;
  height: 36px !important;
  justify-content: center;
}

.employeeNameHolder {
  position: absolute;
  top: 9px;
  word-wrap: break-word;
  width: 10rem;
  left: 8rem;
  height: 4rem;
  contain: content;
  overflow: auto;
}

.react-dropdown-select-content {
  font-family: poppins !important;
  max-width: calc(100% - 21px);
  flex-wrap: nowrap !important;
}

.react-dropdown-select-content>span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.popupCloseButton {
  margin-left: auto;
  position: absolute;
  right: 13px;
  top: -28px;
}
.header-row th {
  padding: 12px;
}
.dashed-bottom {
  border-bottom: 1px dashed #bfbfbf;
}
.template-table-header {
  background-color: white;
}
.template-table-header th {
  font-weight: 400;
}
.templateTableContainer {
  height: calc(100% - 273px);
  overflow: auto;
}
.completedHeading {
    display: flex;
    width: 90%;
    margin-top: 4rem;
}



.completedName {
    width: 15rem;
    margin-left: 2rem;
}

.completedCategory {
    width: 14rem;
}

.completedCourse {
    width: 18rem;
}

.completedChapter {
    width: 19rem;
}

.completedStatus {
    width: 13rem;
}

.completedStatusdesign {
    padding: 4px;
    color: white;
    width: 90.303px;
    text-align: center;

    height: 28.297px;
    border-radius: 4px;
    background: var(--people-s-ol-primery, #1296B0);
}

.inprogressStatusdesign {
    padding: 5px;
    text-align: center;
    color: var(--travel, #F7941D);
    width: 90.303px;
    height: 28.297px;
    border-radius: 4px;
    background: var(--travel-pastel, #FFF4E5);

}

.completedDate {
    width: 11rem;
}

.progressBar {
    height: 4px;
    width: 90%;
    background-color: #BFBFBF;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    position: relative;
}



.progressBarPercentage {
    color: var(--people-sol-dark-grey, #555);
    margin-left: 10px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.751px;
    letter-spacing: -0.48px;
}
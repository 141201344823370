.repeatedHeading {
  color: rgb(18, 150, 176);
  font-weight: 500;
  padding-left: 13px;
  font-size: large;
}

.tabIconContainer {
  height: 90px;
  display: flex;
  justify-content: center;
  position: relative;
  width: 455px;
  border-radius: 4px;
  contain: content;
}

.tabIconBox {
  height: 90px;
  width: 13rem;
  box-shadow: 3px 1.84076px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  justify-content: end;
  position: relative;
}

.flightIconBg {
  background: linear-gradient(258.14deg, #8a88ed 1.84%, #615eda 86.17%);
}

.trainIconBg {
  background: linear-gradient(258.14deg, #ff8b76 1.84%, #fc5638 86.17%);
}

.busIconBg {
  background: linear-gradient(258.14deg, #f394d8 1.84%, #d951b2 86.17%);
}

.cabIconBg {
  background: linear-gradient(258.14deg, #75dbd6 1.84%, #3dbab4 86.17%);
}

.tabIconImage {
  position: absolute;
  text-align: center;
  top: 17px;
  right: 15%;
}

.tabIconText {
  position: absolute;
  left: 12px;
  top: 22px;
  color: #d7d6fc;
}

.flightActiveFlightStyle {
  transition: 0.2s ease-in-out;
  z-index: 4;
  position: absolute;
  left: 0;
}

.flightActiveTrainStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  z-index: 3;
  left: 91px;
}

.flightActiveBusStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  left: 182px;
  z-index: 2;
}

.flightActiveCabStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  left: 273px;
}

.trainActiveFlightStyle {
  transition: 0.2s ease-in-out;
  z-index: 2;
  position: absolute;
  left: -91px;
}

.trainActiveTrainStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  z-index: 4;
  left: 91px;
}

.trainActiveBusStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  left: 182px;
  z-index: 2;
}

.trainActiveCabStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  left: 273px;
}

.busActiveFlightStyle {
  transition: 0.2s ease-in-out;
  z-index: 4;
  position: absolute;
  left: -91px;
}

.busActiveTrainStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  z-index: 3;
  left: 0;
}

.busActiveBusStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  left: 182px;
  z-index: 4;
}

.busActiveCabStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  left: 273px;
  z-index: 3;
}

.cabActiveFlightStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  left: -91px;
  z-index: 3;
}

.cabActiveTrainStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  z-index: 2;
  left: 0;
}

.cabActiveBusStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  left: 91px;
  z-index: 1;
}

.cabActiveCabStyle {
  transition: 0.2s ease-in-out;
  position: absolute;
  left: 273px;
  z-index: 4;
}

.reimbursementTablein .reimbursementTablediv {
  width: 194px;
}

.reimbursementTablein .css-1ygcj2i-MuiTableCell-root {
  padding: 0px !important;
}

.reimbursementTablein {
  display: flex;
  flex-shrink: 0;
}
.attendanceCalendar {
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  overflow: auto;
  height: 100%;
  padding-right: 2px;
}

.attendanceHolder {
  width: 15%;
  background-color: #f4fbfc;
  overflow: auto;
  flex-shrink: 0;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  contain: content;
  flex-direction: column;
  padding: 12px;
  gap: 18px;
}

.attendanceMainContainer {
  display: flex;
  justify-content: space-evenly;
  max-height: calc(100% - 48px);
  gap: 12px;
  contain: content;
  padding: 12px;
}

.attendanceBoxHolder {
  display: flex;
  border: 2px solid #e7f8ec;
  height: 65px;
  width: 100%;
  border-radius: 8px;
  background-color: white;
}

.attendanceLeftBox {
  width: 6rem;
  font-size: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attendanceRightBox {
  display: flex;
  flex-direction: column;
  height: 69%;
  justify-content: center;
}

.dayLabel {
  height: 16px;
  font-weight: 500;
  color: #555555;
  font-size: 14px;
  width: 65px;
  line-height: 16px;
  padding-top: 4px;
}

.lineOverText {
  margin-top: 0;
}

.myAttendanceHolder {
  height: 85%;
}

.ant-picker-calendar-date-value {
  font-size: 30px;
  font-family: poppins;
  padding-top: 10px;
  font-weight: 500;
}

.ant-picker-calendar-date-content {
  height: 71px !important;
}

.attendanceContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 8rem;
  padding: 4px;
  margin: 2px;
  position: relative;
  border-top: 1px solid #bfbfbf;
  contain: content;
  overflow: auto;
}

.dateDisplay {
  font-size: 26px;
  font-weight: 400;
  font-family: poppins;
  margin-top: -3px;
}

.attendanceContentStatus {
  font-weight: 500;
  color: #6ab1bf;
  align-self: flex-start;
}

.attendanceIn {
  font-size: 12px;
  font-weight: 350;
  color: var(--primary);
  white-space: nowrap;
}

.attendanceOut {
  font-size: 12px;
  font-weight: 350;
  color: var(--secondary);
  white-space: nowrap;
}

.attendanceContentContainer>.ant-picker-cell-inner {
  padding: 0 !important;
  height: 12rem !important;
  contain: content;
  overflow: auto;
}

.calendarLine {
  position: absolute;
  background-color: transparent;
  right: 1px;
  top: 32px;
}

.calendarSvg {
  margin-right: 4px;
}

.myTeamHolder {
  position: absolute;
  display: flex;
  width: 66%;
  top: 12px;
}

.myHolder {
  display: flex;
  align-items: center;
  height: 44px;
  border: 1px solid #bfbfbf;
  border-radius: 6px;
  padding-right: 8px;
}

.teamHolder {
  display: flex;
  height: 44px;
  border: 1px solid #bfbfbf;
  border-radius: 6px;
  align-items: center;
  margin-left: 10px;
  padding: 5px 5px 5px 0;
  gap: 9px;
}

.ant-select-selector {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  border-color: #bfbfbf !important;
}

.ant-radio-button-wrapper {
  height: 40px !important;
  /* display: flex !important; */
  align-items: center !important;
  /* border-color: #BFBFBF !important; */
  display: none !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #555555;
  background: #ffffff;
  border-color: var(--primary) !important;
}

.ant-select {
  height: 40px !important;
}

.ant-radio-group {
  display: flex !important;
}

.noShrink {
  flex-shrink: 0;
}

.activeClass {
  border-color: var(--primary);
}

.invisibleClass {
  display: none;
}

.monthLabel {
  height: 16px;
  font-weight: 500;
  color: #555555;
  font-size: 11px;
  width: 43px;
  padding-top: 2px;
  line-height: 13px;
}

.monthDetailHolder {
  display: flex;
  justify-content: space-between;
  margin: 6px;
  width: 76px;
}

.monthDataHolder {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}

.monthRightBox {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
}

.ant-picker-calendar-date-content {
  height: auto !important;
  padding-top: 6px;
}

.monthCount {
  font-size: 28px;
  font-weight: 500;
}

.attendanceLoader {
  display: "flex";
  justify-content: "center";
  align-items: "center";
  margin-top: 20rem;
}

.regularisedIconContainer {
  background: #6ab1bf;
  border-radius: 100%;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 24px;
  margin-left: 6px;
  margin-top: 8px;
  color: white;
}

.rIconClass {
  background: inherit !important;
}

.regularisedIconContainerDashboard {
  background: #6ab1bf;
  border-radius: 100%;
  width: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  height: 14px;
  margin-left: 6px;
  margin-top: 8px;
  color: white;

}

.ant-select-dropdown {
  border-radius: 0 !important;
}

.attendanceLoadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 9;
}

.cardDateDisplayRegularise {
  font-size: 11px;
  font-weight: 500;
  font-family: poppins;
  display: flex;
  justify-content: center;
  padding: 8px 5px 0px 1px;
}
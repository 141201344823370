.surveyContainer {
  width: 100%;
}
.surveyWrapper {
  display: flex;
  justify-content: center;
}
.socialSurveyHeading {
  color: #555 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  background: #d8f0f2 !important;
  text-transform: none !important;
  min-height: 42px !important;
  min-width: 150px !important;
}
.socialSurveyActive {
  background-color: #1296b0 !important;
  color: #fff !important;
}
/* .surveyContainer .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #555;
  font-weight: 500;
  font-size: 14px;
  background: #d8f0f2;
  text-transform: none !important;
  min-height: 42px;
  min-width: 150px;
} */
.socialSurveyTabs {
  min-height: 42px !important;
  border-radius: 10px;
}
.surveycreateform .lmrxhc {
  padding: 0px;
}
.surveycreateform {
  overflow-x: auto;
  width: 100%;
}
.surveyCheckbox-addBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  width: 100%;
}
.surveyCheckCell {
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: #555;
}

.selectSurveyPostTypePopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}

.selectSurveyPostTypePopupBox2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  max-height: 425px;
  border-radius: 6px;
  min-height: 425px;
  min-width: 500px;
  max-width: 500px;
  left: -60px;
  margin-top: 50px;
}

.add-survey-btn {
  font-size: 18px;
  background: #d8f0f2;
  color: #1296b0;
  border-radius: 20%;
  border: none;
  padding: 0px 4px;
  margin-right: 5px;
}

.surveyAddBtnContainer {
  font-size: 12px !important;
  color: #1296b0;
  cursor: pointer;
}
.surveyTableHeaderContainer .inputSelect {
  background: #ffffff;
  color: var(--primary);
  border: 1px solid var(--primary) !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02) !important;
  border-radius: 4px !important;
  width: calc(100% - 24px) !important;
  margin: 12px;
  padding: 12px;
  box-sizing: border-box;
  outline: 0;
  min-height: 40px;
  margin-left: 20px;
}
.surveyTableHeaderContainer {
  background-color: var(--white);
  margin-bottom: -6px;
  display: flex;
  align-items: center;
  min-height: 50px;
  height: 50px;
  padding: 0px 30px;
  margin-top: 20px;
}
.survayMainDisplayListContainer {
  width: 100%;
  /* padding: 0px 30px 0px 35px; */
}
.survayMainDisplayList {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
  margin: 20px 25px 20px 30px;
  border-radius: 8px;
  overflow: hidden;
}

.surveyAndPollTeamHeaderContainer,
.surveyAndPollPersonHeaderContainer,
.surveyAndPollCommunityHeaderContainer {
  padding: 10px 20px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
}

.surveyAndPollTeamHeaderWrapper,
.surveyAndPollPersonHeaderWrapper,
.surveyAndPollCommunityHeaderWrapper,
.surveyAndPollTeamHeaderLeftContainer,
.surveyAndPollCommunityHeaderLeftContainer,
.surveyAndPollPersonHeaderLeftContainer,
.surveyAndPollTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.surveyProfilePic {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.survayMainDisplayListWrapper {
  overflow: auto;
  height: calc(100vh - 280px);
}
.survayMainDisplayListWrapper::-webkit-scrollbar {
  display: none;
}

.surveyCustomProfilePic {
  color: var(--primary);
  width: 42px;
  height: 42px;
  font-size: 21px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.surveyAndPollPersonHeaderTxtContainer,
.surveyAndPollTeamHeaderTxtContainer,
.surveyAndPollCommunityHeaderTxtContainer {
  margin: 0px 10px;
  line-height: 20px;
}
.surveyAndPollPersonHeaderTxtContainer h6,
.surveyAndPollTeamHeaderTxtContainer h6,
.surveyAndPollCommunityHeaderTxtContainer h6 {
  font-size: 16px;
}
.surveyAndPollPersonHeaderTxtContainer label,
.surveyAndPollTeamHeaderTxtContainer label,
.surveyAndPollCommunityHeaderTxtContainer label {
  margin-right: 5px;
}
.surveyAndPollPersonHeaderTxtContainer span,
.surveyAndPollTeamHeaderTxtContainer span,
.surveyAndPollCommunityHeaderTxtContainer span {
  color: #bfbfbf;
}
.surveyAndPollCommunityHeaderTxtContainer h6,
.surveyAndPollTeamHeaderTxtContainer h6 {
  color: #1296b0;
}
.surveyPostDot {
  margin-right: 3px;
  bottom: 3px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.surveyCreatedOn {
  font-size: 12px;
}
.surveyAndPollTitleContainer {
  padding: 15px 20px;
}
.surveyAndPollTitleContainer h3 {
  font-size: 18px;
  text-transform: capitalize;
  /* margin-bottom: 10px; */
}
.surveyAndPollDescriptionContainer {
  padding: 20px;
}
.surveyAndPollDescriptionContainer p {
  margin: 10px 0px;
}

.surveyDateTimeContainer {
  display: flex;
  align-items: center;
}
.surveyDateTimeContainer .surveyStartTimeContainer {
  margin-right: 30px;
}
.surveyDateTimeContainer .surveyStartTimeContainer label,
.surveyDateTimeContainer .surveyEndTimeContainer label {
  font-size: 13px;
  font-weight: 500;
}
.surveyStartTime,
.surveyEndTime {
  display: flex;
  align-items: center;
  color: #1296b0;
  background-color: #d8f0f2;
  padding: 6px 10px;
  border-radius: 5px;
}
.surveyStartTime span,
.surveyEndTime span {
  margin: 0px 7px;
}

.viewReportBox {
  color: #1296b0;
  font-weight: 500;
  background-color: #d8f0f2;
  padding: 7px 14px;
  border-radius: 5px;
}
.titleViewReport {
  display: flex;
  align-items: center;
}
.viewReportBoxContainer .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px 10px;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 500;
}
.viewReportBoxContainer .css-1yxmbwk {
  font-size: 14px !important;
  padding: 0 !important;
}
.viewReportBoxContainer
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: white;
}
.surveyPostActivityContainer {
  position: relative;
}
.questionAnswerContainer {
  border-top: 1px solid #ebebeb;
}
.questionAnswerWrapper {
  padding: 15px 20px;
}
.questionAnswerList {
  padding-bottom: 20px;
}
.questionAnswerList label {
  font-weight: 500;
}
.answerOptionsList .answerOption {
  font-size: 13px;
  margin-left: 7px;
}
.answerOptionsList svg {
  cursor: pointer;
}
.answerOptionsList {
  display: flex;
  margin: 10px 0;
  width: 50%;
}

.surveyDetailsContainer {
  /* height: 100vh; */
  width: 100%;
  padding: 0px 30px 0px 35px;
}
.surveyDetailsWrapper {
  height: 600px;
  overflow: auto;
  padding-bottom: 100px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
}
.surveyDetailsWrapper::-webkit-scrollbar {
  display: none;
}
.surveyDetailsHeader {
  display: flex;
  align-items: center;
  padding: 12px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
}
.surveyDetailsHeaderText {
  margin-left: 10px;
  color: #1296b0;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
}
.surveyDetailsData {
  padding: 10px 20px;
}
.surveyDetailsParaContainer {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
.surveyDetailsSurveyOptionsContainer h5 {
  margin: 10px 0px;
}
.surveyDetailsSurveyOptionsWrapper {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.surveyDetailsSurveyOptionsWrapper div {
  width: 23%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}
.surveyDetailsSurveyOptionsWrapper span {
  font-size: 13px;
  cursor: pointer;
}
.surveyDetailsSurveyOptionsWrapper label {
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
}
.surveyDetailsSurveyOptionsWrapper .optionAll {
  color: #1296b0;
  background-color: #eff9fa;
}
.surveyDetailsSurveyOptionsWrapper .optionAll:hover {
  color: #eff9fa;
  background-color: #1296b0;
}
.surveyDetailsSurveyOptionsWrapper .optionView {
  color: #0db53c;
  background-color: #e7f8ec;
}
.surveyDetailsSurveyOptionsWrapper .optionView:hover {
  color: #e7f8ec;
  background-color: #0db53c;
}
.surveyDetailsSurveyOptionsWrapper .optionRespond {
  color: #8a88ed;
  background-color: #f0f0ff;
}
.surveyDetailsSurveyOptionsWrapper .optionRespond:hover {
  color: #f0f0ff;
  background-color: #8a88ed;
}
.surveyDetailsSurveyOptionsWrapper .optionNotRespond {
  color: #f7941d;
  background-color: #fff4e5;
}
.surveyDetailsSurveyOptionsWrapper .optionNotRespond:hover {
  color: #fff4e5;
  background-color: #f7941d;
}
.surveyRadioButtonComponent,
.surveyCheckBoxComponent {
  display: flex;
  flex-wrap: wrap;
}

.surveyDetailsFullQuestionAnswer {
  border-radius: 8px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
}
.surveyDetailsQuestion {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
}
.surveyDetailsAnswersListContainer {
  padding: 15px;
}
.surveyDetailsGraphWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0px 15px;
}
.surveyDetailsGraphWrapper .surveyDetailsGraphBar {
  width: 80%;
  /* padding: 0px 15px; */
}
.surveyDetailsGraphData {
  width: 20%;
  display: flex;
  align-items: center;
}
/* .surveyDetailsGraphData label {
  display: flex;
  align-items: center;
  width: 100% !important;

} */
.surveyDetailsBtn {
  margin: 0px 5px !important;
  padding: 2px !important;
  font-size: 13px !important;
  color: #1296b0;
  min-width: 70% !important;
  background-color: #d8f0f2 !important;
  font-weight: 500;
}
.surveyDetailsGraphWrapper span {
  margin-left: 10px;
}
.SurveyReportPageContainer .donutchart {
  height: 250px !important;
  width: 200px !important;
}
.SurveyReportPageContainer {
  padding: 4px 25px 20px 30px;
  padding-bottom: 70px;
}
.SurveyReportChartTitleContainer {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  overflow: hidden;
}
.SurveyReportPageWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  /* justify-content: space-between; */
}

.SurveyReportPageWrapper div {
  width: 33%;
}
.SurveyReportPageWrapper svg {
  padding: 0 10%;
}
.SurveyReportPageWrapper p {
  text-align: center;
}
.SurveyReportPageContainer::-webkit-scrollbar {
  display: none;
}
.surveyReportTabs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0;
}
.surveyReportTabs .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
  border-radius: 5px !important;
}
.allReportHeading,
.viewReportHeading,
.respondReportHeading,
.notRespondReportHeading {
  border-bottom: 3px solid #ebebeb;
  padding: 2% 4%;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
}
.allReportHeading {
  color: #1296b0;
}
.viewReportHeading {
  color: #0db53c;
}
.respondReportHeading {
  color: #8a88ed;
}
.notRespondReportHeading {
  color: #f7941d;
}
.allReportActive {
  border-bottom-color: #1296b0;
}
.viewReportActive {
  border-bottom-color: #0db53c;
}
.respondReportActive {
  border-bottom-color: #8a88ed;
}
.notRespondReportActive {
  border-bottom-color: #f7941d;
}
.displayAgeAndGenderListWrapper {
  display: flex;
  align-items: center;
  margin: 6px 0;
}
.displayAgeAndGenderListContainer {
  padding-left: 10%;
}
.displayAgeAndGenderListWrapper p {
  color: #555 !important;
  font-size: 14px !important;
}

.circleChart {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-right: 8px;
}
.bgColor1 {
  background-color: #1296b0;
}
.bgColor2 {
  background-color: #f7941d;
}
.bgColor3 {
  background-color: #8a88ed;
}
.bgColor4 {
  background-color: #fb7158;
}
.bgColor5 {
  background-color: #77a2c7;
}
.bgColor6 {
  background-color: #de64a1;
}
.bgColor7 {
  background-color: #b69fb3;
}
.bgColor8 {
  background-color: #e9b000;
}
.bgColor9 {
  background-color: #ff5733;
}
.bgColor10 {
  background-color: #48c9b0;
}
.bgColor11 {
  background-color: #ffc300;
}
.bgColor12 {
  background-color: #9b59b6;
}
.bgColor13 {
  background-color: #3498db;
}
.bgColor14 {
  background-color: #2ecc71;
}
.bgColor15 {
  background-color: #e74c3c;
}
.bgColor16 {
  background-color: #f39c12;
}
.bgColor17 {
  background-color: #1abc9c;
}
.bgColor18 {
  background-color: #1abc9c;
}
.bgColor19 {
  background-color: #1abc9c;
}
.bgColor20 {
  background-color: #1abc9c;
}

.textColor1 {
  color: #1296b0;
}
.textColor2 {
  color: #f7941d;
}
.textColor3 {
  color: #8a88ed;
}
.textColor4 {
  color: #fb7158;
}
.textColor5 {
  color: #77a2c7;
}
.textColor6 {
  color: #de64a1;
}
.textColor7 {
  color: #b69fb3;
}
.textColor8 {
  color: #e9b000;
}
.textColor9 {
  color: #ff5733;
}
.textColor10 {
  color: #48c9b0;
}
.textColor11 {
  color: #ffc300;
}
.textColor12 {
  color: #9b59b6;
}
.textColor13 {
  color: #3498db;
}
.textColor14 {
  color: #2ecc71;
}
.textColor15 {
  color: #e74c3c;
}
.textColor16 {
  color: #f39c12;
}
.textColor17 {
  color: #1abc9c;
}
.textColor18 {
  color: #1abc9c;
}
.textColor19 {
  color: #1abc9c;
}
.textColor20 {
  color: #1abc9c;
}

.ageAndGenderDetailContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ageAndGenderDetailWrapper tr {
  margin: 5px 0px;
}
.ageAndGenderDetailWrapper th {
  font-weight: 500;
}
.ageAndGenderDetailWrapper td,
.ageAndGenderDetailWrapper th {
  text-align: center;
  padding: 15px;
}
.ageAndGenderDetailWrapper p {
  font-size: 16px;
}
.ageAndGenderDetailWrapper span {
  font-size: 12px;
}

.genderReportWrapper {
  padding: 20px 0 !important;
}
.genderReportWrapper,
.ageReportWrapper,
.locationReportWrapper,
.departmentReportWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ebebeb;
  padding: 30px 0;
}
.genderReportList,
.ageReportList,
.locationReportList,
.departmentReportList {
  width: 50%;
  padding-right: 5%;
}

.genderReportNumbeAndPercentContainer,
.ageReportNumberAndPercentContainer,
.locationReportNumberAndPercentContainer,
.locationReportNumberAndPercentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.report-container {
  padding: 0 30px;
}
.genderDetail-container,
.ageDetail-container,
.locationDetail-container,
.departmentDetail-container {
  width: 50%;
}
.genderReportNumbeAndPercentContainer,
.ageReportNumberAndPercentContainer,
.locationReportNumberAndPercentContainer,
.departmentReportNumberAndPercentContainer {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.genderReportList-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.surveyBoxShadow {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
}
/* .hideSurveyScrollBar::-webkit-scrollbar {
  display: none;
} */
.surveyDetailsAnswers {
  margin-bottom: 10px;
}
.downloadSurveyBtn {
  background-color: #d8f0f2;
  border-radius: 5px;
}
.downloadSurveyBtn span {
  color: #1296b0;
  margin-right: 10px;
}
.socialEngageMainContainer .createPageContainer {
  top: 0;
  left: 0;
  z-index: 1;
}
.downloadSurveyBtn .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  border-radius: 5px;
  padding: 0;
}
.pollAnswerOptionsList {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}
.pollDetailsAnswersListContainer {
  width: 100%;
  margin: 0px 10px;
  border: 1px soliD #e2f4f5;
  padding: 10px 15px;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.pollDetailsAnswers {
  width: 95%;
}
.pollDetailsGraphContainer {
  margin-top: 10px;
}
.submitPollAndSurveyResponse {
  display: flex;
  justify-content: end;
  margin: 20px;
}
.surveyNote {
  background-color: #faede8;
  padding: 10px;
  border-radius: 7px;
  margin-bottom: 10px;
}
.surveyNote h5 {
  color: #f4a177;
  font-size: 15px;
}
.surveyNote p {
  font-size: 12px;
}
.surveyNote span {
  position: relative;
  bottom: 2px;
  font-size: 16px;
  right: 3px;
  font-weight: bold;
}
.surveyPremiumBtnContainer {
  display: flex;
  justify-content: end;
  padding-top: 20px;
}
.surveyPremiumPostPopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.surveyPremiumPostPopupBox2 {
  position: relative;
  top: 3%;
  display: flex;
  flex-direction: column;
  contain: content;
  background: #fff;
  border-radius: 8px;
  border: none;
  max-height: 85%;
  min-height: 250px;
  max-width: 30%;
  min-width: 30%;
}
.surveyPremiumPostPopupBox2 .createHead {
  padding: 9px 15px;
}
.surveyPremiumPostPopupBox2 .popupContentHolder {
  padding: 0px;
  justify-content: unset;
}
.surveyPremiumPopupContainer {
  padding: 15px;
}
.surveyPremiumHeaderPopupBox2 {
  color: #1296b0;
  font-size: 18px;
  font-weight: 500;
}
.surveyPremiumPopupContainer h6 {
  color: #292929;
  font-size: 15px;
  border-bottom: 1px solid #1296b0;
  padding-bottom: 20px;
}
.surveyPremiumFeatureContainer {
  background: #d8f0f2;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 20px 0px;
}
.surveyPremiumFeatureContainer h4 {
  color: #1296b0;
  font-size: 16px;
  margin-bottom: 5px;
}
.surveyPremiumFeatureContainer article {
  margin: 5px 0px;
}
.surveyPremiumFeatureContainer article img {
  margin-right: 10px;
}
.surveyPremiumFeatureContainer article span {
  color: #292929;
  font-size: 15px;
  font-weight: 500;
}
.surveyPremiumPriceContainer {
  background: #f7941d;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
}
.surveyPremiumPriceContainer h5 {
  font-size: 14px;
}
.surveyPremiumPriceContainer span {
  font-size: 28px;
  font-weight: 500;
}
.surveyPremiumPopupContainer h3 {
  color: #292929;
  margin: 20px 0px;
  font-size: 14px;
}
.surveyPremiumBuyContainer a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background-color: #1296b0;
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.surevyQuestionSeperator {
  width: 100%;
  padding-top: 25px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}
.surveyCommunitySerach {
  position: relative !important;
}
.surveynewPostForEmployeePopup2 {
  max-height: 90%;
  min-height: 80%;
  min-width: 500px;
}

.surveyConfirmBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.surveyConfirmBox2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  left: -40px;
  background: #fff;
  border-radius: 8px;
  border: none;
  max-height: 85%;
  min-height: 250px;
  max-width: 500px;
  margin-top: 40px;
}
.surveyConfirmBox2 p {
  margin: 0;
}
.selectCategoryListContainer {
  z-index: 99;
}
.selectCategoryListContainer .infinite-scroll-component__outerdiv {
  background-color: #fff;
  /* height: 100%; */
  width: 30%;
  position: relative;
  left: 30%;
  top: 2%;
}
.selectCategoryListContainer .infinite-scroll-component {
  background-color: #fff;
  height: 600px !important;
  width: 100%;
  position: absolute;
  border-radius: 8px;
}
.selectCategoryListContainer .infinite-scroll-component::-webkit-scrollbar {
  display: none;
}
.employeeListInfinitScroll {
  width: 100% !important;
  height: 100% !important;
  background: #00000050;
  position: absolute;
  left: 0;
  top: 0;
}
.employeeListInfinitScroll .selectedEmployeeList {
  padding: 10px;
}
.employeeListInfinitScroll .employeeList {
  display: flex;
  align-items: center;
}
.employeeListInfinitScroll .totalLikePopupImg {
  margin-right: 10px;
}
.employeeListInfinitScrollHeader {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
  position: fixed;
  background: #fff;
  width: 30%;
  z-index: 10;
  top: 76px;
  border-radius: 5px 5px 0 0;
}
.employeeListInfinitScrollHeaderBackBtn {
  display: flex;
  align-items: center;
}
.employeeListInfinitScrollHeaderBackBtn p {
  color: #1296b0;
  font-size: 16px;
}

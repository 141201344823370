.earningTypeItem {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  padding: 12px;
 
  border-radius: 8px;
}
.earningTypeListContainer {
  font-family: "Poppins";
  padding: 12px 0;
  height: calc(100% - 67px);
  overflow: auto;
}
.sety{
  margin-top: 20px;
 font-size: 14px;
 margin: 0 12px;
 font-weight: 500;
 font-family: "Poppins";
 font-size: 15px;
}
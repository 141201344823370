.navigation {
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  height: 4.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}
.menu {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
}
.menuContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.menu #Ellipse7 {
  transform: translateY(0) translateX(0);
  transition: 0.5s;
}

.menu #Ellipse9 {
  transform: translateY(0) translateX(0);
  transition: 0.5s;
}
.menu #Ellipse10 {
  transform: translateX(0) translateY(0);
  transition: 0.5s;
}
.menu #Ellipse12 {
  transform: translateY(0) translateX(0);
  transition: 0.5s;
}
.menuOpen #Ellipse7 {
  transform: translateY(20.5%) translateX(-19.5%);
  transition: 0.5s;
}

.menuOpen #Ellipse9 {
  transform: translateY(-20.5%) translateX(19.5%);
  transition: 0.5s;
}
.menuOpen #Ellipse10 {
  transform: translateX(19.5%) translateY(20.5%);
  transition: 0.5s;
}
.menuOpen #Ellipse12 {
  transform: translateY(-20.5%) translateX(-19.5%);
  transition: 0.5s;
}
.menu:hover {
  cursor: pointer;
  color: var(--primary);
}
.menuIcon {
  height: 100%;
}
.menuText {
  font-size: 12px;
  margin: 0;
  align-self: center;
  margin-bottom: 0.5rem;
}
.navi {
  display: flex;
  width: 100%;
  height: 100%;
}
.verticalLine {
  height: 100%;
  width: 1px;
  align-self: center;
  background-color: #bfbfbf;
}
.menuLogo {
  width: 2.5rem;
  height: 100%;
}
.searchBar {
  height: 100%;
  width: 20rem;
  margin-left: auto;
  margin-right: 20px;
}
.actionIconText {
  display: flex;
  gap: 0;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  align-items: center;
}
.actionButton {
  display: flex;
  gap: 0;
  flex-direction: column;
  height: 4.5rem;
  width: 40px;
  justify-content: center;
  margin: 0 1rem;
  position: relative;
}
.actionIconText > svg {
  height: 2rem;
}
.myServiceIcon {
  height: 26px;
  border: 1px solid var(--primary);
  border-radius: 100%;
  padding: 2px;
  aspect-ratio: 1;
}
.actionButton:hover {
  cursor: pointer;
  color: var(--primary);
}
.actionText {
  font-size: 12px;
  margin: 0;
  align-self: center;
  white-space: nowrap;
}
.actionTextActive {
  color: var(--primary);
}
.actionIcon {
  height: 2rem;
}
.line {
  background-color: var(--primary);
  height: 3px;
  width: 100%;
  border-radius: 10px;
}
.navHeading {
  margin: 0 1.5rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--primary);
  align-self: center;
}

.nav-light {
  background-color: var(--bg-light);
}

.nav-dark {
  background-color: var(--bg-dark);
}

.nav-logo {
  width: 195px;
  height: 65%;
  align-self: center;
}

.loggedin {
  margin-top: -0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav-profile {
  cursor: pointer;
}
.navBarSearch {
  margin-left: auto;
  width: 30% !important;
}

.chatsBadge {
  top: 7%;
  right: 10.2%;
}
.approvalsBadge {
  top: 7%;
  right: 0;
}
.notificationsBadge {
  top: 7%;
  right: 0;
}
.searchDropdown {
  position: absolute;
  background-color: white;
  max-height: 14rem;
  border-radius: 4px;
  display: none;
  overflow-y: auto;
  width: 26rem;
  /* top: 1.5rem; */
  right: 2px;
}
.searchDDHolder {
  position: relative;
  width: 89%;
  height: 12rem;
}
.searchDropdownActive {
  display: flex;
  width: 100%;
}
.searchListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.searchListLabel {
  height: 30px;
  font-size: 16px;
  font-family: inherit;
  padding-left: 10px;
  padding-top: 4px;
  color: #555555;
  font-weight: 500;
}
.searchListLabel:hover {
  background-color: #e0f0f1;
  color: var(--primary);
}
.searchActiveContainer {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

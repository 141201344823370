.moduleMasterDetailsPage {
  position: absolute;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabularMasterDetailsPageContainer {
  width: 80% !important;
  padding: 0 !important;
}

.tabularMasterDetailsPageBody {
  background-color: var(--form-background);
  flex-grow: 1;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 53px);
}

.moduleMasterDetailsBackground {
  height: 100%;
  flex-grow: 1;
}

.moduleMasterDetailsPageContainer {
  height: 100%;
  width: 50%;
  background-color: white;
  z-index: 11;
  display: flex;
  flex-direction: column;
  background-color: var(--form-background);
}

.moduleMasterDetailsPageBody {
  gap: 24px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  margin: 24px;
  height: calc(100% - 101px);
  background-color: white;
  overflow: auto;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
  border-radius: 4px;
}

.moduleMasterDetailsPageHeader {
  border-bottom: 1px solid var(--grey);
  margin-bottom: 12px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.moduleMasterDetailsBox {
  display: grid;
  grid-template-areas: "a a a";
  grid-auto-columns: 40% 10% 50%;
}

.moduleMasterDetailsValue {
  color: var(--primary);
  font-weight: 500;
  overflow-wrap: anywhere;
}

.tabularMasterDetailsPageTabBodyContainer {
  margin: 24px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  background-color: var(--white);
  padding: 36px 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  max-height: calc(100% - 60px);
  overflow: auto;
}

.detailData {
  display: flex;
  height: fit-content;
  gap: 12px;
  width: calc(50% - 36px);
  align-items: center;
}

.detailDataLD {
  display: flex;
  height: fit-content;
  gap: 12px;
  /* width: calc(50% - 36px); */
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
}

.detailDatas {
  display: flex;
  height: fit-content;
  gap: 12px;
  width: 100%;
  align-items: center;
}

.detailsValue {
  color: var(--primary);
  font-weight: 500;
  overflow-wrap: anywhere;
}

.detailData>label:first-child {
  width: 35%;
  flex-shrink: 0;
  overflow-wrap: anywhere;
}

.detailDataLD>label:first-child {
  width: 30%;
  flex-shrink: 0;
  overflow-wrap: anywhere;
}